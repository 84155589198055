import styled from "@emotion/styled";
import { IonProgressBar } from "@ionic/react";
import { useEffect, useState } from "react";
import { formatMillis } from "../../../helpers/formathelpers";
import { QuizQuestion } from "../../../types/Quiz";

const ProgressContainer = styled("div")`
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    height: 32px;
    background: ${({ theme }) => theme.colors.primary.gunmetal["1000"]};
`;

const ProgressBar = styled(IonProgressBar)`
    &::part(progress) {
        background: ${({ theme }) => theme.colors.primary.carolinaBlue};
    }
    &::part(track) {
        background: ${({ theme }) => theme.colors.primary.beauBlue};
    }
`;

const ProgressLabel = styled("p")`
    ${({ theme }) => theme.text.paragraph.tiny};
    color: ${({ theme }) => theme.colors.white["640"]};
    min-width: 40px;
    text-align: right;
    padding: 0;
    margin: 0;
`;

const MILLIS_INTERVAL = 100;

interface TimerProgressBarProps {
    currentQuestion: QuizQuestion | undefined;
    handleNextQuestion: () => void;
}

const TimerProgressBar = (props: TimerProgressBarProps) => {
    const { currentQuestion, handleNextQuestion } = props;

    const [millisRemaining, setMillisRemaining] = useState<number>();
    const [isTimerRunning, setIsTimerRunning] = useState(false);

    const questionTime = currentQuestion?.time;

    useEffect(() => {
        if (millisRemaining === undefined || !isTimerRunning) {
            return;
        }

        if (millisRemaining <= 0) {
            handleNextQuestion();
            return;
        }

        const timeout = setTimeout(() => {
            setMillisRemaining(millisRemaining - MILLIS_INTERVAL);
        }, MILLIS_INTERVAL);

        return () => {
            clearTimeout(timeout);
        };
    }, [handleNextQuestion, isTimerRunning, millisRemaining]);

    useEffect(() => {
        if (questionTime) {
            setMillisRemaining(questionTime * 1000);
            setIsTimerRunning(true);
        } else {
            setMillisRemaining(undefined);
            setIsTimerRunning(false);
        }
    }, [questionTime, currentQuestion?.id]);

    return (
        <ProgressContainer>
            <ProgressBar
                value={
                    millisRemaining === undefined || questionTime === undefined
                        ? 1
                        : millisRemaining / (questionTime * 1000)
                }
            />
            <ProgressLabel>{formatMillis(millisRemaining)}</ProgressLabel>
        </ProgressContainer>
    );
};

export default TimerProgressBar;
