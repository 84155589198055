import React from "react";
import styled from "@emotion/styled";

const TitleWrapper = styled("div")<{
    type?: string;
}>`
    background-color: ${({ theme, type }) =>
        type === "light"
            ? "transparent"
            : theme.colors.primary.gunmetal["1000"]};
    color: ${({ type, theme }) =>
        type === "light"
            ? theme.colors.primary.gunmetal["1000"]
            : theme.colors.white["1000"]};
    padding: 24px 16px 40px 0;
`;
const HeadlineWrapper = styled("h1")`
    ${({ theme }) => theme.text.h1}
    margin: 0;
`;
const TextWrapper = styled("p")<{
    text?: string;
    type?: string;
}>`
    ${({ theme }) => theme.text.paragraph.big}
    margin: 0;
    color: ${({ theme }) => theme.colors.white["640"]};
    ${({ text }) =>
        text &&
        `
            margin-top: 4px;
        `}
    ${({ type, theme }) =>
        type === "light" &&
        `
            color: ${theme.colors.primary.gunmetal["640"]};
        `}
`;

interface TitleHeadlineProps {
    headline: string;
    text?: string;
    type?: string;
}

const TitleHeadline = (props: TitleHeadlineProps) => {
    const { headline, text, type } = props;

    return (
        <TitleWrapper type={type}>
            <HeadlineWrapper>{headline}</HeadlineWrapper>
            <TextWrapper text={text} type={type}>
                {text}
            </TextWrapper>
        </TitleWrapper>
    );
};

export default TitleHeadline;
