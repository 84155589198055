import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import styled from "@emotion/styled";
import { FreeMode } from "swiper";
import placeholder from "./../../../assets/img/placeholder.svg";
import { IonCard, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import {
    useWindowHeight,
    useWindowWidth,
} from "../../../hooks/redux/useWindowWidth";

const StyledSwiper = styled(Swiper)<{
    direction: "horizontal" | "vertical";
}>`
    width: 100%;
    height: auto;
    padding-bottom: ${({ direction }) =>
        direction === "vertical" ? "40px" : "0"};
`;

const Card = styled(IonCard)`
    position: relative;
    width: 293px;
    height: 295px;
    margin-inline: 0;
    border-radius: 0;
    margin: 0;
    box-shadow: none;
    background: ${({ theme }) => theme.colors.white["1000"]};
`;

const Image = styled("img")`
    height: 200px;
    width: 100%;
    background: ${({ theme }) => theme.colors.white["1000"]};
    object-fit: cover;
    object-position: 0 20%;
`;

const ContentWrapper = styled("div")`
    height: 93px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 16px;
`;

const SubTitle = styled(IonCardSubtitle)`
    ${({ theme }) => theme.text.paragraph.tiny}
    margin-top: -4px;
    padding: 0;
    color: ${({ theme }) => theme.colors.gray["640"]};
    margin-bottom: 4px;
    text-transform: none;
`;

const Title = styled(IonCardTitle)`
    ${({ theme }) => theme.text.h6}
    margin: 0;
    color: ${({ theme }) => theme.colors.primary.gunmetal["1000"]};
`;

const StyledSwiperSlide = styled(SwiperSlide)``;

type ItemType = {
    id: number;
    title: string;
    subTitle: string;
    photo: string;
    onClick: () => void;
};

interface LargeCardSwiperProps {
    items: ItemType[];
    direction: "horizontal" | "vertical";
}

export const LargeCardSwiper = (props: LargeCardSwiperProps) => {
    const { items, direction } = props;
    const windowWidth = useWindowWidth();
    const windowHeight = useWindowHeight();
    const slidesPerViewWidth = (windowWidth - 20) / 315; // width card + margin
    const slidesPerViewHeight = (windowHeight - 40) / 335; // height card + margin

    return (
        <StyledSwiper
            slidesPerView={
                direction === "horizontal"
                    ? windowWidth < 999
                        ? slidesPerViewWidth
                        : 3.111111111
                    : slidesPerViewHeight
            }
            spaceBetween={direction === "horizontal" ? 20 : 40}
            modules={[FreeMode]}
            freeMode={true}
            direction={direction}
            className="mySwiper"
        >
            {items.map((item) => (
                <StyledSwiperSlide key={item.id} onClick={item.onClick}>
                    <Card>
                        <Image
                            alt={item.title}
                            src={item.photo ?? placeholder}
                        />
                        <ContentWrapper>
                            <SubTitle>{item.subTitle}</SubTitle>
                            <Title>{item.title}</Title>
                        </ContentWrapper>
                    </Card>
                </StyledSwiperSlide>
            ))}
        </StyledSwiper>
    );
};

export default LargeCardSwiper;
