import * as React from "react";
import Loader from "../../Controls/Loader/Loader";

import { NotFoundText } from "../ArticleFiltersModal/ArticlesFiltersModal";

import { useLazyGetArticlesQuery } from "../../../services/article";
import { ArticleListItem } from "./ArticleListItem";
import { IonCol, IonGrid, IonInfiniteScroll, IonRow } from "@ionic/react";
import { PullDownIndicator } from "../../Controls/PullDownIndicator/PullDownIndicator";
import { Article } from "../../../types/Article";
import { useInfiniteQuery } from "../../../hooks/useInfiniteQuery";

interface ArticleGeneralProps {
    search: string;
    categories: number[];
    forBrand?: boolean;
}

const KnowledgeSections = (props: ArticleGeneralProps) => {
    const { search, categories, forBrand } = props;

    const { combinedData, isLoading, isFetching, readMore, hasMore } =
        useInfiniteQuery<Article>(useLazyGetArticlesQuery, {
            search,
            per_page: 10,
            for_brand: forBrand ?? false,
            category_ids:
                categories && categories.length > 0 ? categories : undefined,
        });

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : combinedData.length === 0 ? (
                <NotFoundText>
                    {search
                        ? `Nie znaleziono artykułu o nazwie ${search}`
                        : `Nie znaleziono artykułu`}
                </NotFoundText>
            ) : (
                <>
                    <IonGrid>
                        <IonRow>
                            {combinedData.map((article) => {
                                return (
                                    <IonCol
                                        key={article.id}
                                        size="6"
                                        size-md="3"
                                    >
                                        <ArticleListItem item={article} />
                                    </IonCol>
                                );
                            })}
                        </IonRow>
                    </IonGrid>
                    <IonInfiniteScroll
                        onIonInfinite={(ev) => {
                            readMore().then(() => {
                                ev.target.complete();
                            });
                        }}
                        disabled={!hasMore}
                    >
                        {isFetching ? <Loader /> : <PullDownIndicator />}
                    </IonInfiniteScroll>
                </>
            )}
        </>
    );
};

export default KnowledgeSections;
