import { IonContent, IonPage } from "@ionic/react";
import { ArticleList } from "../../components/Articles/ArticleList/ArticleList";
import HeaderMainComplex from "../../components/Layout/Header/HeaderMainComplex";

export const ArticleListPage = () => {
    return (
        <IonPage>
            <IonContent fullscreen>
                <HeaderMainComplex headline="Baza wiedzy" />
                <ArticleList />
            </IonContent>
        </IonPage>
    );
};
