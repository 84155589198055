import React from "react";
import styled from "@emotion/styled";

const BottomBar = styled("div")`
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 32px 16px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray["500"]};
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
`;

interface LoginNavProps {
    children?: React.ReactNode;
}

const LoginNav = (props: LoginNavProps) => {
    const { children } = props;

    return <BottomBar>{children}</BottomBar>;
};

export default LoginNav;
