import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import Button from "../Buttons/Button";
// import { FiSearch } from "react-icons/fi";
import { IconFilter } from "../../Icons/FullIconsList";
import Input from "../Inputs/Input";
import { ReactElement } from "react";

const Container = styled("div")`
    padding: 0 16px;
    display: flex;
    background: ${({ theme }) => theme.colors.gradient["dark-to-bottom"]};
    width: 100%;
`;
const Col = styled("div")`
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
`;
const AutoCol = styled("div")`
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
`;

interface SearchFormProps {
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
    onClickButton?: React.ReactEventHandler;
    icon?: ReactElement;
}

const SearchForm = (props: SearchFormProps) => {
    const { placeholder, value, onClickButton, onChange, icon } = props;
    const theme = useTheme();

    return (
        <Container>
            <Col>
                <Input
                    icon={icon}
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            </Col>
            <AutoCol>
                {onClickButton && (
                    <Button
                        type={["gray", "height56", "icon"]}
                        onClick={onClickButton}
                    >
                        <IconFilter
                            color={theme.colors.gray["1000"]}
                            height={20}
                        />
                    </Button>
                )}
            </AutoCol>
        </Container>
    );
};

export default SearchForm;
