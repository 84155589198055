import React from "react";
import styled from "@emotion/styled";
import { ReactComponent as ChevronDownSVG } from "../../../assets/icons/chevron-down.svg";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
`;

const Wrapper = styled("div")`
    height: fit-content;
    width: 100%;

    margin: auto;
    text-align: center;

    animation: 800ms ease-in-out 1s ${fadeIn} both;
`;

const ChevronDown = styled(ChevronDownSVG)`
    height: 35px;
    width: 35px;

    path {
        stroke: ${({ theme }) => theme.colors.gray["640"]};
    }
`;

export const PullDownIndicator = () => {
    return (
        <Wrapper>
            <ChevronDown />
        </Wrapper>
    );
};
