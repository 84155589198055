import React from "react";
import StyledSvg from "./StyledSvg";

interface IconHomeProps {
    height?: number;
    color?: string;
    display?: string;
}
const IconHome = (props: IconHomeProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                className="stroke"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className="stroke"
                    d="M5.5 8.44447V21H9.16667V17H15.8333V21H19.5V8.44447M3.5 10L11.2721 3.95505C11.9943 3.39332 13.0057 3.39332 13.7279 3.95505L21.5 10"
                />
            </svg>
        </StyledSvg>
    );
};

const IconHomeFull = (props: IconHomeProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                className="stroke fill"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className="fill"
                    d="M19.5 21V8.44441L13.7279 3.95499C13.0057 3.39326 11.9943 3.39326 11.2721 3.95499L5.5 8.44441V21H9.16667V17H15.8333V21H19.5Z"
                />
                <path
                    className="stroke"
                    d="M5.5 8.44441V21H9.16667V17H15.8333V21H19.5V8.44441M3.5 9.99997L11.2721 3.95499C11.9943 3.39326 13.0057 3.39326 13.7279 3.95499L21.5 9.99997"
                />
            </svg>
        </StyledSvg>
    );
};
export { IconHome, IconHomeFull };
