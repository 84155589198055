import * as React from "react";
import Loader from "../../Controls/Loader/Loader";
import { QuizListItem } from "./QuizListItem";
import { NotFoundText } from "../../Articles/ArticleFiltersModal/ArticlesFiltersModal";
import { useGetQuizzesQuery } from "../../../services/quiz";

interface QuizListDoneProps {
    search: string;
}

const QuizListDone = (props: QuizListDoneProps) => {
    const { search } = props;
    const {
        currentData: quizzesDoneData,
        isFetching: isFetchingQuizzesDone,
        isSuccess: loadingQuizzesDoneSucceeded,
    } = useGetQuizzesQuery({ done: true, search });

    return (
        <>
            {isFetchingQuizzesDone ? (
                <Loader />
            ) : loadingQuizzesDoneSucceeded ? (
                quizzesDoneData.data.length === 0 ? (
                    <NotFoundText>
                        {search
                            ? `Nie znaleziono quizu o nazwie ${search}`
                            : `Nie znaleziono quizu`}
                    </NotFoundText>
                ) : (
                    quizzesDoneData.data.map((quiz) => (
                        <QuizListItem isDone={true} key={quiz.id} item={quiz} />
                    ))
                )
            ) : null}
        </>
    );
};

export default QuizListDone;
