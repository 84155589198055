import { IonContent, IonPage } from "@ionic/react";
import { useParams } from "react-router-dom";
import {
    HeaderMain,
    HeaderTitle,
} from "../../components/Layout/Header/HeaderMainStyles";

import ArticleContent from "../../components/Articles/Article/ArticleContent";
import Loader from "../../components/Controls/Loader/Loader";
import { useGetArticleQuery } from "../../services/article";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { GoBackButton } from "../../components/Controls/Buttons/GoBackButton";

export const StyledIonPage = styled(IonPage)`
    position: absolute;
    z-index: 9999;
`;

export const ArticlePage = () => {
    const params = useParams<{ id: string }>();

    const postId = Number(params.id) ?? null;

    const contentRef = useRef<HTMLIonContentElement>(null);

    const {
        currentData: articleData,
        isFetching,
        isSuccess,
    } = useGetArticleQuery(postId ?? skipToken);

    useEffect(() => {
        contentRef.current?.scrollToPoint(0, 0);
    }, [postId]);

    return (
        <StyledIonPage>
            <IonContent fullscreen ref={contentRef}>
                <HeaderMain size="small">
                    <GoBackButton />
                    {isSuccess && !isFetching && (
                        <HeaderTitle>{articleData.data.title}</HeaderTitle>
                    )}
                </HeaderMain>
                {isFetching ? (
                    <Loader fullScreen />
                ) : isSuccess ? (
                    <ArticleContent article={articleData.data} />
                ) : null}
            </IonContent>
        </StyledIonPage>
    );
};
