import React from "react";
import styled from "@emotion/styled";
import { IonSpinner } from "@ionic/react";

const LoaderWrapper = styled("div")<{ fullScreen: boolean | undefined }>`
  height: fit-content;
  width: fit-content;
  text-align: center;
  margin: auto;
  ${({ fullScreen }) =>
    fullScreen && {
      position: "fixed",
      inset: "0",
    }}
`;

interface LoaderProps {
  fullScreen?: boolean;
}

const Loader = (props: LoaderProps) => {
  const { fullScreen } = props;

  return (
    <LoaderWrapper fullScreen={fullScreen}>
      <IonSpinner name="dots" />
    </LoaderWrapper>
  );
};

export default Loader;
