import styled from "@emotion/styled";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { LevelHelper } from "../../../helpers/LevelHelper";
import { Cocktail } from "../../../types/Cocktail";
import Stars from "../../Controls/Stars/Stars";
import { ListItemDescription } from "../../Layout/List/ListItem/ListItemDescription";
import { ListItemImageWrapper } from "../../Layout/List/ListItem/ListItemImageWrapper";
import { ListItemTitle } from "../../Layout/List/ListItem/ListItemTitle";
import { StyledListItemLinkWrapper } from "../../Quizzes/QuizList/QuizListItem";

const IonRowCenter = styled(IonRow)`
    align-items: center;
`;
const LevelLabel = styled("p")`
    ${({ theme }) => theme.text.paragraph.tiny}
    position: relative;
    color: ${({ theme }) => theme.colors.primary.gunmetal["400"]};
    margin: 4px 0 0 0;
    text-align: right;
`;

interface CocktailListItemProps {
    item: Cocktail;
}

export const CocktailListItem = (props: CocktailListItemProps) => {
    const { item } = props;
    const stars = LevelHelper(item.difficulty_level);

    return (
        <StyledListItemLinkWrapper to={`/recipe/${item.id}`}>
            <IonGrid>
                <IonRowCenter>
                    <IonCol size="auto">
                        <ListItemImageWrapper backgroundUrl={item.photo_path} />
                    </IonCol>
                    <IonCol>
                        <ListItemTitle>{item.name}</ListItemTitle>
                        <ListItemDescription>{item.taste}</ListItemDescription>
                    </IonCol>
                    {item.difficulty_level && (
                        <IonCol size="auto">
                            <Stars stars={stars} />
                            <LevelLabel>{item.difficulty_level}</LevelLabel>
                        </IonCol>
                    )}
                </IonRowCenter>
            </IonGrid>
        </StyledListItemLinkWrapper>
    );
};
