import styled from "@emotion/styled";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { EffectCards, Pagination } from "swiper";
import placeholder from "./../../../assets/img/placeholder.svg";
import { IonCol } from "@ionic/react";
import { useEffect, useState } from "react";

const StyledSwiperSlide = styled(SwiperSlide)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.white["1000"]};
    box-shadow: 8px 8px 40px -33px rgba(66, 68, 90, 1);
    .swiper-slide-shadow {
        background: ${({ theme }) => theme.colors.white["1000"]};
    }
`;

const StyledIonCol = styled(IonCol)`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0;
    flex-direction: column;
    align-items: center;
`;

const SlideText = styled("h5")`
    ${({ theme }) => theme.text.h5}
    margin: 0;
    color: ${({ theme }) => theme.colors.gray["1000"]};
`;
const SlideSubheaderText = styled("p")`
    ${({ theme }) => theme.text.paragraph.tiny}
    margin: 0;
    color: ${({ theme }) => theme.colors.gray["640"]};
    margin-bottom: 2px;
`;
const ImageBox = styled("div")<{
    backgroundUrl?: string;
}>`
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin-bottom: 16px;
    background-image: url(${({ backgroundUrl }) =>
        backgroundUrl ?? placeholder});
    background-position-y: 20%;
    background-size: cover;
    background-repeat: no-repeat;
`;

const StyledSwiper = styled(SwiperComponent)`
    width: 100%;
    max-width: 343px;
    height: 302px;
    .swiper-pagination {
        bottom: var(--swiper-pagination-bottom, 15px);
        span {
            background: ${({ theme }) => theme.colors.primary.carolinaBlue};
        }
    }
`;

interface SlideSwiperProps {
    items: {
        title: string;
        subTitle?: string;
        photo?: string;
        onClick: () => void;
        id: number;
    }[];
}

const SlideSwiper = (props: SlideSwiperProps) => {
    const { items } = props;

    const [enable, setEnable] = useState(false);

    // The horrific abomination below is the only solution we found
    // to make Swiper work on every render
    // sorry :c
    useEffect(() => {
        const timeout = setTimeout(() => setEnable(true), 1);
        return () => {
            clearTimeout(timeout);
            setEnable(false);
        };
    }, []);

    if (!enable) return null;

    return (
        <StyledSwiper
            effect={"cards"}
            pagination={true}
            modules={[EffectCards, Pagination]}
        >
            {items.map((item, index) => (
                <StyledSwiperSlide onClick={item.onClick} key={item.id}>
                    <StyledIonCol>
                        <ImageBox backgroundUrl={item.photo}></ImageBox>
                        {!!item.subTitle && (
                            <SlideSubheaderText>
                                {item.subTitle}
                            </SlideSubheaderText>
                        )}
                        <SlideText>{item.title}</SlideText>
                    </StyledIonCol>
                </StyledSwiperSlide>
            ))}
        </StyledSwiper>
    );
};

export default SlideSwiper;
