import { IonContent, IonPage } from "@ionic/react";
import { CocktailList } from "../../components/Cocktails/CocktailList/CocktailList";
import HeaderMainComplex from "../../components/Layout/Header/HeaderMainComplex";

export const CocktailListPage = () => {
    return (
        <IonPage>
            <IonContent fullscreen>
                <HeaderMainComplex headline="Koktajle" />
                <CocktailList />
            </IonContent>
        </IonPage>
    );
};
