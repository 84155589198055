import React from "react";
import StyledSvg from "./StyledSvg";

interface IconListProps {
    height?: number;
    color?: string;
    display?: string;
}
const IconList = (props: IconListProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className="stroke"
                    d="M8.5 16.5H15.5M8.5 12H15.5M8.5 7.5H15.5M5 3.5625V21H19V12V3.5625L15.5 4.125L12 3L8.5 4.125L5 3.5625Z"
                />
            </svg>
        </StyledSvg>
    );
};
export { IconList };
