import moment from "moment";
import "moment/locale/pl";

export const formatDate = (date: string) =>
    moment(date).locale("pl").format("DD MMMM YYYY");

export const formatDateMonthLong = (date: string) => {
    return new Date(date).toLocaleDateString("pl-PL", {
        day: "2-digit",
        month: "long",
        year: "numeric",
    });
};

export const formatMinutes = (minutes: number | undefined) =>
    `${minutes || 0} min`;

export const formatMillis = (millis?: number) => {
    const time = moment(millis);

    return time.format("mm:ss");
};

export const extractMinutes = (string?: string) => {
    const minutesString = string?.match("(?<minutes>[0-9.]+) ?(min)?")?.groups
        ?.minutes;

    return minutesString ? parseFloat(minutesString) : undefined;
};
