import React, { useEffect, useMemo, useRef } from "react";
import styled from "@emotion/styled";
import { IonModal, useIonRouter, useIonToast } from "@ionic/react";
import { HeaderMain } from "../components/Layout/Header/HeaderMainStyles";
import TitleHeadline from "../components/Layout/Header/TitleHeadline";
import Logo from "../components/Layout/Header/Logo";
import bottomBackground from "./../assets/img/loginBackground.jpg";
import topBackground from "./../assets/img/logoShape.svg";
import { ModalButton } from "../components/Controls/Buttons/ModalButton";
import { QuizButtonsWrapper } from "../components/Quizzes/Quiz/QuizButtonsWrapper";
import { alertCircleOutline, checkmarkCircleOutline } from "ionicons/icons";
import { useAppSelector } from "../hooks/redux/useAppSelector";
import { useLoginMutation } from "../services/auth";
import { useAppDispatch } from "../hooks/redux/useAppDispatch";
import { logIn } from "../store/slices/auth";
import { Redirect } from "react-router";
import Loader from "../components/Controls/Loader/Loader";
import { ssoConfig } from "../authConfig";

const Wrapper = styled("div")`
    height: 100%;
    display: flex;
    background-image: linear-gradient(
            to top,
            rgba(255, 255, 255, 100) 100px,
            rgba(255, 255, 255, 0) 140px
        ),
        url(${topBackground}), url(${bottomBackground});
    --background: transparent;
    background-size: 100%, contain, cover;
    background-position: center, bottom -10% center, center;
    background-repeat: no-repeat;
    background-color: white;
    justify-content: space-between;
    flex-direction: column;
    padding: 16px 16px 32px;
`;

export const LoginPage = () => {
    const router = useIonRouter();

    const [present] = useIonToast();

    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);

    const [backendLogin, { isLoading }] = useLoginMutation();

    const redirectProcessed = useRef<boolean>(false);

    const code = useMemo(
        () => new URLSearchParams(router.routeInfo.search).get("code"),
        [router.routeInfo.search]
    );

    const handleLogin = async () => {
        const url = new URL(ssoConfig.loginUrl);

        url.searchParams.append("client_id", ssoConfig.clientId);
        url.searchParams.append("scope", ssoConfig.scope);
        url.searchParams.append("response_type", ssoConfig.responseType);
        url.searchParams.append("response_mode", ssoConfig.responseMode);
        url.searchParams.append("redirect_uri", ssoConfig.redirectUri);

        window.open(url.toString());
    };

    useEffect(() => {
        if (code && !isLoggedIn && !redirectProcessed.current) {
            redirectProcessed.current = true;
            backendLogin({ code, redirect_uri: ssoConfig.redirectUri })
                .unwrap()
                .then((data) => {
                    present({
                        message: `Witaj, ${data.name}!`,
                        icon: checkmarkCircleOutline,
                        animated: true,
                        position: "top",
                        color: "success",
                        duration: 5000,
                    });
                    dispatch(logIn(data));
                })
                .catch((e) => {
                    present({
                        message: "Wystąpił błąd z logowaniem. Spróbuj ponownie",
                        icon: alertCircleOutline,
                        animated: true,
                        position: "top",
                        color: "danger",
                        duration: 5000,
                    });
                })
                .finally(() => {
                    router.push("/login", "none", "replace");
                });
        }
    }, [backendLogin, code, dispatch, isLoggedIn, present, router]);

    if (isLoggedIn) return <Redirect to={"/dashboard"} />;

    return (
        <IonModal isOpen={true}>
            <Wrapper>
                <HeaderMain inModal={true} color="light">
                    <Logo type="dark" />
                    <TitleHeadline
                        headline="Zaloguj się"
                        text="Labore sunt veniam amet est."
                        type="light"
                    />
                </HeaderMain>
                <QuizButtonsWrapper>
                    <ModalButton
                        onClick={handleLogin}
                        disabled={isLoading}
                        fullWidth
                    >
                        {isLoading ? <Loader /> : "Pernod Ricard SSO"}
                    </ModalButton>
                </QuizButtonsWrapper>
            </Wrapper>
        </IonModal>
    );
};
