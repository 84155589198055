const themeText = {
    h1: {
        fontFamily: "'Recia', serif",
        fontWeight: "600",
        fontSize: "36px",
        lineHeight: "120%",
    },
    h2: {
        fontFamily: "'Recia', serif",
        fontWeight: "600",
        fontSize: "32px",
        lineHeight: "120%",
    },
    h3: {
        fontFamily: "'Recia', serif",
        fontWeight: "600",
        fontSize: "28px",
        lineHeight: "120%",
    },
    h4: {
        fontFamily: "'Recia', serif",
        fontWeight: "600",
        fontSize: "24px",
        lineHeight: "120%",
    },
    h5: {
        fontFamily: "'Recia', serif",
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "120%",
    },
    h6: {
        fontFamily: "'Recia', serif",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "120%",
    },
    h7: {
        fontFamily: "'Recia', serif",
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "120%",
    },
    paragraph: {
        huge: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "150%",
        },
        big: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "150%",
        },
        regular: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "150%",
        },
        small: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "150%",
        },
        tiny: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "150%",
        },
    },
    italic: {
        huge: {
            fontFamily: "Satoshi",
            fontStyle: "italic",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "150%",
        },
        big: {
            fontFamily: "Satoshi",
            fontStyle: "italic",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
        },
        regular: {
            fontFamily: "Satoshi",
            fontStyle: "italic",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
        },
        small: {
            fontFamily: "Satoshi",
            fontStyle: "italic",
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "150%",
        },
        tiny: {
            fontFamily: "Satoshi",
            fontStyle: "italic",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "150%",
        },
    },
    bold: {
        huge: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "150%",
        },
        big: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "150%",
        },
        regular: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "150%",
        },
        small: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "13px",
            lineHeight: "150%",
        },
        tiny: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "150%",
        },
    },
    link: {
        huge: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "150%",
            textDecorationLine: "underline",
        },
        big: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            textDecorationLine: "underline",
        },
        regular: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
            textDecorationLine: "underline",
        },
        small: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "150%",
            textDecorationLine: "underline",
        },
        tiny: {
            fontFamily: "Satoshi",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "150%",
            textDecorationLine: "underline",
        },
    },
};

export default themeText;
