import styled from "@emotion/styled";
import { IonButton } from "@ionic/react";

export const ModalButton = styled(IonButton)<{
    color?: "white";
    fullWidth?: boolean;
}>`
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
    text-transform: capitalize;
    white-space: normal;
    height: 48px;
    color: ${({ theme, color }) =>
        color === "white"
            ? theme.colors.gray["1000"]
            : theme.colors.white["1000"]};
    border: ${({ theme, color }) =>
        color === "white" ? `1px solid ${theme.colors.gray["240"]}` : "none"};

    &::part(native),
    &::part(native)::after {
        border-radius: 0;
        background: ${({ theme, color }) =>
            color === "white"
                ? theme.colors.white["1000"]
                : theme.colors.primary.carolinaBlue};
    }
`;
