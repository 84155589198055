import React from "react";
import styled from "@emotion/styled";

const StyledSvg = styled("div")<{
    height?: number;
    color?: string;
    display?: string;
}>`
    height: ${({ height }) => (height ? `${height}px` : "16px")};
    display: ${({ display }) => (display ? display : "inline-block")};
    svg {
        height: 100%;
        width: auto;
        margin: 0;
        padding: 0;
        vertical-align: top;
    }
    .stroke {
        stroke: ${({ color }) => (color ? color : "#000000")}!important;
        stroke-width: 1.6;
        stroke-miterlimit: 10;
    }
    .fill {
        fill: ${({ color, theme }) => (color ? color : "#000000")}!important;
    }
`;

interface IconTasteProps {
    height?: number;
    color?: string;
    display?: string;
    children?: React.ReactNode;
}

const IconTaste = (props: IconTasteProps) => {
    const { height, color, display, children } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            {children}
        </StyledSvg>
    );
};

export default IconTaste;
