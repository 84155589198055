import styled from "@emotion/styled";

export const QuizButtonsWrapper = styled("div")`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    padding: 8px 8px 32px;
    width: 100%;
    justify-content: space-between;

    & > * {
        flex: 1;
        flex-shrink: 1;
    }

    z-index: 3;
    border-top: solid 1px ${({ theme }) => theme.colors.gray["500"]};
    background: ${({ theme }) => theme.colors.white["1000"]};
`;
