import { devices } from "./../../theme/devices";
import { useState, useEffect } from "react";

export const useWindowWidth = () => {
    const [useCheckWindowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return useCheckWindowWidth;
};

export const useWindowHeight = () => {
    const [useCheckWindowHeight, setWindowHeight] = useState(
        window.innerHeight
    );

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return useCheckWindowHeight;
};

export const useCheckWindowWidth = (device: keyof typeof devices) => {
    const useCheckWindowWidth = useWindowWidth();
    const deviceWidthString =
        devices[device].match(/(?<width>[0-9.]+)/)?.groups?.width;

    if (!deviceWidthString) return false;

    const deviceWidth = Number(deviceWidthString);

    return useCheckWindowWidth <= deviceWidth;
};
