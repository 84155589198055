import ButtonIcon from "../../Controls/Buttons/ButtonIcon";
import styled from "@emotion/styled";
import { useState } from "react";
import GlassType from "./CoctailsFilters/GlassType";
import Taste from "./CoctailsFilters/Taste";
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg";
import { HeaderMain, HeaderTitle } from "../../Layout/Header/HeaderMainStyles";
import {
    FilterItemBox,
    IconWrapper,
    StyledModal,
    StyledOverlay,
    Subtitle,
} from "../../Articles/ArticleFiltersModal/ArticlesFiltersModal";
import { useCheckWindowWidth } from "../../../hooks/redux/useWindowWidth";
import { useGetGlassTypesQuery } from "../../../services/glassTypes";
import { useGetTastesQuery } from "../../../services/taste";
import {
    difficultyLevel,
    displayChoices,
    displayNameChoices,
} from "../../../helpers/filtersHelpers";
import { useGetIngredientsQuery } from "../../../services/ingredient";
import Ingredients from "./CoctailsFilters/Ingredients";
import DifficultLevel from "./CoctailsFilters/DifficultLevel";
import { useCocktailsContext } from "../../../hooks/useAppContext";
import { IconChevronLeft } from "../../Icons/IconChevronLeft";
import { ExitIcon } from "../../Icons/ExitIcon";

export const Wrapper = styled("div")`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 16px 95px;
    overflow: scroll;
    align-items: flex-start;
`;
export const NotFoundText = styled("p")`
    width: 100%;
    text-align: center;
`;
export const FilterContainer = styled("div")`
    width: 100%;
`;
export const FilterItem = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.text.h6}
    padding: 30px 0;
    border-bottom: 1px solid
        ${({ theme }) => theme.colors.primary.gunmetal["160"]};
`;

interface CocktailsFiltersModalProps {
    isOpen: boolean;
    setOpenModal: (isOpen: boolean) => void;
}

const CategoriesModal = (props: CocktailsFiltersModalProps) => {
    const { isOpen, setOpenModal } = props;
    const { state } = useCocktailsContext();
    const isTabletSize = useCheckWindowWidth("tablet");
    const [filterBy, setFilterBy] = useState("");

    const { data: glassTypeData } = useGetGlassTypesQuery({
        per_page: 50,
    });
    const { data: tasteData } = useGetTastesQuery({
        per_page: 50,
    });
    const { data: ingredientsData } = useGetIngredientsQuery({
        per_page: 50,
    });

    const availableFilters = [
        {
            id: 1,
            name: "Typ szklanki",
            category: "glass-type",
            choiceCategory: displayNameChoices(glassTypeData, state.glassTypes),
        },
        {
            id: 2,
            name: "Smak",
            category: "taste",
            choiceCategory: displayNameChoices(tasteData, state.tastes),
        },
        {
            id: 3,
            name: "Składink",
            category: "ingredient",
            choiceCategory: displayNameChoices(
                ingredientsData,
                state.ingredients
            ),
        },
        {
            id: 4,
            name: "Stopień strudności",
            category: "difficult_level",
            choiceCategory: displayNameChoices(
                difficultyLevel,
                state.difficultyLevel
            ),
        },
    ];

    const handleCloseModal = () => {
        if (filterBy) {
            setFilterBy("");
        } else setOpenModal(false);
    };

    const handleChangeFilters = () => {
        switch (filterBy) {
            case "glass-type":
                return (
                    <GlassType
                        setOpenModal={setOpenModal}
                        choicesCocktails={displayChoices(
                            glassTypeData,
                            state.glassTypes
                        )}
                    />
                );
            case "taste":
                return (
                    <Taste
                        setOpenModal={setOpenModal}
                        choicesCocktails={displayChoices(
                            tasteData,
                            state.tastes
                        )}
                    />
                );
            case "ingredient":
                return (
                    <Ingredients
                        setOpenModal={setOpenModal}
                        choicesCocktails={displayChoices(
                            ingredientsData,
                            state.ingredients
                        )}
                    />
                );
            case "difficult_level":
                return (
                    <DifficultLevel
                        setOpenModal={setOpenModal}
                        choicesCocktails={displayChoices(
                            difficultyLevel,
                            state.difficultyLevel
                        )}
                    />
                );
        }
    };

    const handleTitleName = () => {
        switch (filterBy) {
            case "glass-type":
                return "typ szklanki";
            case "taste":
                return "smak";
            case "ingredient":
                return "składnik";
            default:
                return "kategorię";
        }
    };

    return (
        <>
            {isOpen && <StyledOverlay onClick={() => setOpenModal(false)} />}
            <StyledModal isOpen={isOpen}>
                <HeaderMain
                    color={!isTabletSize ? "light" : undefined}
                    size="small"
                >
                    <ButtonIcon
                        color={isTabletSize ? "light" : "dark"}
                        onClick={handleCloseModal}
                    >
                        {filterBy ? <IconChevronLeft /> : <ExitIcon />}
                    </ButtonIcon>

                    <HeaderTitle>Wybierz {handleTitleName()}</HeaderTitle>
                </HeaderMain>

                <Wrapper>
                    {!filterBy ? (
                        <FilterContainer>
                            {availableFilters.map((filter) => (
                                <FilterItem
                                    key={filter.id}
                                    onClick={() => setFilterBy(filter.category)}
                                >
                                    <FilterItemBox>
                                        {filter.name}
                                        <Subtitle>
                                            {filter.choiceCategory}
                                        </Subtitle>
                                    </FilterItemBox>
                                    <IconWrapper>
                                        <ChevronRight />
                                    </IconWrapper>
                                </FilterItem>
                            ))}
                        </FilterContainer>
                    ) : (
                        handleChangeFilters()
                    )}
                </Wrapper>
            </StyledModal>
        </>
    );
};
export default CategoriesModal;
