import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { devices } from "../../theme/devices";
import { isPathActive } from "../../helpers/isPathActive";

const HeaderNavWrapper = styled("div")`
    margin-left: 30px;
    @media only screen and (max-width: ${devices.tablet}) {
        display: none;
    }
`;
const NavItem = styled(NavLink)`
    margin-left: 28px;
    color: ${({ theme }) => theme.colors.white["640"]};
    text-decoration: none;

    &.active {
        color: ${({ theme }) => theme.colors.white["1000"]};
    }
`;

const HeaderNav = () => {
    return (
        <HeaderNavWrapper>
            <NavItem to="/dashboard">Home</NavItem>
            <NavItem to="/knowledge-base">Baza wiedzy</NavItem>
            <NavItem to="/training">Szkolenia</NavItem>
            <NavItem
                isActive={(match, location) =>
                    isPathActive("/quizzes", location)
                }
                to="/quizzes/undone"
            >
                Quizy
            </NavItem>
            <NavItem to="/recipes">Koktajle</NavItem>
        </HeaderNavWrapper>
    );
};

export default HeaderNav;
