import { useReducer, useRef, useState } from "react";
import { ListWrapper } from "../../Layout/List/ListWrapper";
import SearchForm from "../../Controls/SearchForm/SearchForm";
import { Magnifier } from "../../Icons/Magnifier";
import {
    ArticlesContext,
    ContextType,
    articlesReducer,
    initialState,
} from "../../../context/ArticleFiltersContext";
import { Redirect, Route, matchPath, useLocation } from "react-router";
import { StyledOutlet } from "../../Quizzes/QuizList/QuizList";
import ArticlesFiltersModal from "../ArticleFiltersModal/ArticlesFiltersModal";
import Segment from "../../Layout/Segment/Segment";
import KnowledgeSections from "./KnowledgeSections";

export const ArticleList = () => {
    const [openModal, setOpenModal] = useState(false);
    const [state, dispatch] = useReducer(articlesReducer, initialState);
    const [search, setSearch] = useState("");
    const location = useLocation();
    const section = matchPath<{ section: "brand" | "knowledge-general" }>(
        location.pathname,
        {
            path: `/knowledge-base/:section(knowledge-general|brand)`,
        }
    )?.params.section;

    const contextValue: ContextType = {
        state,
        dispatch,
    };

    const scrollRef = useRef<HTMLDivElement>(null);

    return (
        <ArticlesContext.Provider value={contextValue}>
            <ArticlesFiltersModal
                forBrand={section === "brand"}
                isOpen={openModal}
                setOpenModal={setOpenModal}
            />
            <SearchForm
                icon={<Magnifier height={25} />}
                placeholder="Znajdź artykuł"
                value={search}
                onChange={(value) => setSearch(value)}
                onClickButton={() => setOpenModal(true)}
            />
            <Segment
                path={"/knowledge-base/:segment(knowledge-general|brand)"}
                segmentConfigurations={[
                    {
                        segmentName: "Wiedza ogólna",
                        paramName: "knowledge-general",
                    },
                    {
                        segmentName: "Brandy",
                        paramName: "brand",
                    },
                ]}
            />
            <ListWrapper ref={scrollRef}>
                <StyledOutlet animated={false}>
                    <Route
                        path="/knowledge-base/knowledge-general"
                        render={() => (
                            <KnowledgeSections
                                search={search}
                                categories={state.categories}
                            />
                        )}
                    />
                    <Route
                        path="/knowledge-base/brand"
                        render={() => (
                            <KnowledgeSections
                                forBrand
                                search={search}
                                categories={state.categories}
                            />
                        )}
                    />
                    <Redirect
                        exact
                        from={"/knowledge-base"}
                        to={"/knowledge-base/knowledge-general"}
                        push={true}
                    />
                </StyledOutlet>
            </ListWrapper>
        </ArticlesContext.Provider>
    );
};
