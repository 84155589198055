import { createContext } from "react";

interface State {
    search: string;
    glassTypes: number[];
    tastes: number[];
    difficultyLevel: number[];
    ingredients: number[];
}
type Action =
    | {
          type:
              | "FILTER_BY_TASTE"
              | "FILTER_BY_INGREDIENTS"
              | "FILTER_BY_DIFFICULTY_LEVEL"
              | "FILTER_BY_GLASS_TYPE";
          payload: number[];
      }
    | {
          type: "SEARCH";
          payload: string;
      };

export interface ContextType {
    state: State;
    dispatch: React.Dispatch<Action>;
}

export const initialState: State = {
    search: "",
    glassTypes: [],
    tastes: [],
    difficultyLevel: [],
    ingredients: [],
};

export const CocktailsContext = createContext<ContextType | null>(null);

export const cocktailsReducer = (state: State, action: Action) => {
    switch (action.type) {
        case "FILTER_BY_TASTE":
            return { ...state, tastes: action.payload };
        case "FILTER_BY_INGREDIENTS":
            return { ...state, ingredients: action.payload };
        case "FILTER_BY_DIFFICULTY_LEVEL":
            return { ...state, difficultyLevel: action.payload };
        case "FILTER_BY_GLASS_TYPE":
            return { ...state, glassTypes: action.payload };
        case "SEARCH":
            return { ...state, search: action.payload };
        default:
            return state;
    }
};
