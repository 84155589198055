import React from "react";
import StyledSvg from "./StyledSvg";

interface IconTasteProps {
    height?: number;
    color?: string;
    display?: string;
}
const IconTaste = (props: IconTasteProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                className="stroke"
                width="15"
                height="17"
                viewBox="0 0 15 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M8.2953 10.8732L7.44609 11.4892C7.08365 11.7521 6.86912 12.1728 6.86912 12.6206V12.6206V14.6667L2 11.6301L3.29614 11.0547V11.0547C4.11149 10.6926 4.70717 9.96582 4.90198 9.09525L4.96942 8.79388M8.2953 10.8732L9.48697 10.6811C10.1119 10.5803 10.7092 10.3512 11.2412 10.0081V10.0081C12.1045 9.45147 12.7558 8.6209 13.0905 7.64973L14 5.01047L8.09814 1.33334M8.2953 10.8732V10.8732C6.95717 10.6558 5.7472 9.90423 4.96942 8.79388V8.79388M8.09814 1.33334L13.9863 5.00192M8.09814 1.33334L5.93357 3.29199C5.20265 3.95337 4.71764 4.84307 4.55776 5.81575V5.81575C4.45451 6.44393 4.49019 7.08712 4.66228 7.70003L4.96942 8.79388" />
            </svg>
        </StyledSvg>
    );
};
export { IconTaste };
