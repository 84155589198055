import React from "react";
import StyledSvg from "./StyledSvg";

interface IconMagnifierProps {
    height?: number;
    color?: string;
    display?: string;
}
const Magnifier = (props: IconMagnifierProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9 16C12.3137 16 15 13.3137 15 10C15 6.68629 12.3137 4 9 4C5.68629 4 3 6.68629 3 10C3 13.3137 5.68629 16 9 16Z"
                    stroke="#222D38"
                    strokeWidth="1.6"
                    strokeMiterlimit="10"
                />
                <path
                    d="M20 20L13 14"
                    stroke="#222D38"
                    strokeWidth="1.6"
                    strokeMiterlimit="10"
                />
            </svg>
        </StyledSvg>
    );
};
export { Magnifier };
