import { ApiRequestMultipleItemsParams } from "../types/Api/ApiRequestParams";
import { ApiResponseMultipleItems } from "../types/Api/ApiResponse";
import { Quiz, QuizAnswer, QuizSummary } from "../types/Quiz";
import { api } from "./api";

export const quizApi = api.injectEndpoints({
    endpoints: (build) => ({
        getQuiz: build.query<Quiz, number>({
            query: (id) => `/quizzes/${id}`,
            providesTags: (res, err, req) =>
                res
                    ? [
                          {
                              type: "QUIZ_SCORE",
                              id: res.id,
                          },
                      ]
                    : [],
        }),
        getQuizzes: build.query<
            ApiResponseMultipleItems<Quiz>,
            ApiRequestMultipleItemsParams<Quiz> & {
                done: boolean;
            }
        >({
            query: (params) => ({
                url: `/quizzes`,
                params,
            }),
            providesTags: (res, err, req) =>
                res
                    ? [
                          "QUIZ_LIST",
                          ...res.data.map(
                              (quiz) =>
                                  ({
                                      type: "QUIZ_SCORE",
                                      id: quiz.id,
                                  } as const)
                          ),
                      ]
                    : ["QUIZ_LIST"],
        }),
        getMainScore: build.query({
            query: () => `/quizzes-main-sore`,
            providesTags: ["MAIN_QUIZ_SCORE"],
        }),
        saveQuizScore: build.mutation<
            QuizSummary,
            {
                quiz_id: number;
                answers: QuizAnswer[];
            }
        >({
            query: ({ quiz_id, answers }) => ({
                url: `/quizzes/${quiz_id}`,
                body: { answers },
                method: "POST",
            }),
            invalidatesTags: (res, err, req) => [
                "QUIZ_LIST",
                "MAIN_QUIZ_SCORE",
                { type: "QUIZ_SCORE", id: req.quiz_id },
            ],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetQuizQuery,
    useGetQuizzesQuery,
    useLazyGetQuizQuery,
    useLazyGetQuizzesQuery,
    useGetMainScoreQuery,
    useLazyGetMainScoreQuery,
    useSaveQuizScoreMutation,
} = quizApi;
