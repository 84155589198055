import { useGetQuizzesQuery } from "../../../services/quiz";
import { NotFoundText } from "../../Articles/ArticleFiltersModal/ArticlesFiltersModal";
import Loader from "../../Controls/Loader/Loader";
import { QuizListItem } from "./QuizListItem";

interface QuizListUnDoneProps {
    search: string;
}

const QuizListUnDone = (props: QuizListUnDoneProps) => {
    const { search } = props;

    const {
        currentData: quizzesToDoData,
        isFetching: isFetchingQuizzesToDo,
        isSuccess: loadingQuizzesToDoSucceeded,
    } = useGetQuizzesQuery({ done: false, search, per_page: 20 });

    return (
        <>
            {isFetchingQuizzesToDo ? (
                <Loader />
            ) : loadingQuizzesToDoSucceeded ? (
                quizzesToDoData.data.length === 0 ? (
                    <NotFoundText>
                        {search
                            ? `Nie znaleziono quizu o nazwie ${search}`
                            : `Nie znaleziono quizu`}
                    </NotFoundText>
                ) : (
                    quizzesToDoData.data.map((quiz) => (
                        <QuizListItem
                            isDone={false}
                            key={quiz.id}
                            item={quiz}
                        />
                    ))
                )
            ) : null}
        </>
    );
};
export default QuizListUnDone;
