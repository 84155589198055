import styled from "@emotion/styled";
import { ReactNode } from "react";
import { devices } from "../../../theme/devices";

const OuterWrapper = styled("div")<{
    marginTop?: number;
}>`
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)}px;
    @media only screen and (min-width: ${devices.tablet}) {
        margin-top: 0;
    }
`;

const InnerWrapper = styled("div")<{ slim?: boolean }>`
    padding: ${({ slim }) => (slim ? "0" : "24px 16px 0")};
    background: ${({ theme }) => theme.colors.white["1000"]};
`;

interface PostContentWrapperProps {
    children: ReactNode | ReactNode[];
    before?: ReactNode | ReactNode[];
    slim?: boolean;
    marginTop?: number;
}

export const PostContentWrapper = (props: PostContentWrapperProps) => {
    const { children, before, slim, marginTop } = props;
    return (
        <OuterWrapper marginTop={marginTop}>
            {before}
            <InnerWrapper slim={slim}>{children}</InnerWrapper>
        </OuterWrapper>
    );
};
