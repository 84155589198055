import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { api } from "../services/api";
import { quizSlice } from "./slices/quiz";
import { authSlice } from "./slices/auth";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/es/storage";

const persistConfig = {
    key: "root",
    storage,
    blacklist: [api.reducerPath],
};

const appReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    [quizSlice.name]: quizSlice.reducer,
    [authSlice.name]: authSlice.reducer,
});

const rootReducer = (
    state: ReturnType<typeof appReducer> | undefined,
    action: AnyAction
) => {
    if (action.type === "auth/logout") return appReducer(undefined, action);
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(api.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
