import { IonContent, IonPage } from "@ionic/react";
import HeaderMainComplex from "../../components/Layout/Header/HeaderMainComplex";
import TrainingList from "../../components/Training/Training/TrainingList";

export const TrainingListPage = () => {
    return (
        <IonPage>
            <IonContent fullscreen>
                <HeaderMainComplex headline="Szkolenia" />
                <TrainingList />
            </IonContent>
        </IonPage>
    );
};

export default TrainingListPage;
