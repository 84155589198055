/** Komponenty */
import { AiOutlineCalendar as CalendarIcon } from "react-icons/ai";
import { ModalButton } from "../../Controls/Buttons/ModalButton";
import { IconTextList } from "../../Layout/IconTextList/IconTextList";
import { IconTextItem } from "../../Layout/IconTextList/IconTextItem";
import { QuizButtonsWrapper } from "./QuizButtonsWrapper";
import { PageContentWrapper } from "../../Layout/Page/PageContentWrapper";
import { PostContentWrapper } from "../../Layout/Post/PostContentWrapper";
import { PostExcerpt } from "../../Layout/Post/PostExcerpt";
import { PostText } from "../../Layout/Post/PostText";
import { PostTitle } from "../../Layout/Post/PostTitle";
import { PostImageWrapper } from "../../Layout/Post/PostImageWrapper";
import { useAppDispatch } from "../../../hooks/redux/useAppDispatch";
import { startQuiz } from "../../../store/slices/quiz";
import { useGetQuizQuery } from "../../../services/quiz";
import { Redirect, useParams } from "react-router";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import Loader from "../../Controls/Loader/Loader";
import { numericIdHelper } from "../../../helpers/numericIdHelper";

export const QuizStart = () => {
    const params = useParams<{ id: string }>();
    const quizId = numericIdHelper(params.id);

    const dispatch = useAppDispatch();

    const { data: quiz, isLoading } = useGetQuizQuery(quizId ?? skipToken);

    if (isLoading) return <Loader fullScreen />;

    if (!quiz) return <Redirect to="/404" />;

    const handleQuizStart = () => {
        dispatch(startQuiz(quiz));
    };

    return (
        <>
            <PostImageWrapper backgroundUrl={quiz.photo_path} />
            <PostContentWrapper>
                <PageContentWrapper>
                    <PostTitle>{quiz.name}</PostTitle>
                    <IconTextList>
                        <IconTextItem
                            text={`Pytania: ${quiz.questions.length}`}
                            icon={<CalendarIcon />}
                        />
                    </IconTextList>
                    <PostExcerpt>{quiz.description_short}</PostExcerpt>
                    <PostText content={quiz.description} />
                </PageContentWrapper>
            </PostContentWrapper>
            <QuizButtonsWrapper>
                <ModalButton onClick={handleQuizStart}>
                    Rozpocznij quiz
                </ModalButton>
            </QuizButtonsWrapper>
        </>
    );
};
