import { useState } from "react";
import styled from "@emotion/styled";
import SearchForm from "../../Controls/SearchForm/SearchForm";
import { IonSegment, IonSegmentButton, IonRouterOutlet } from "@ionic/react";
import { ListWrapper } from "../../Layout/List/ListWrapper";
import { PageContentWrapper } from "../../Layout/Page/PageContentWrapper";
import { Magnifier } from "../../Icons/Magnifier";
import { WrapListContainer } from "../../Dashboard/DashboardContent";
import QuizListUnDone from "./QuizListUnDone";
import QuizListDone from "./QuizListDone";
import { Redirect, Route } from "react-router-dom";
import Segment from "../../Layout/Segment/Segment";

export const StyledIonSegment = styled(IonSegment)`
    margin: 40px auto 0;
    max-width: 344px;
    padding: 4px;
    height: 45px;
    border-radius: 0;
    background: ${({ theme }) => theme.colors.gray["160"]};
    color: ${({ theme }) => theme.colors.gray["1000"]};
`;

export const StyledOutlet = styled(IonRouterOutlet)`
    position: relative;
`;

export const StyledIonSegmentButton = styled(IonSegmentButton)`
    --color: ${({ theme }) => theme.colors.gray["640"]};
    --color-checked: ${({ theme }) => theme.colors.gray["1000"]};

    &::part(indicator-background) {
        border-radius: 0;
        box-shadow: none;
    }
`;

export const QuizList = () => {
    const [search, setSearch] = useState("");

    return (
        <>
            <SearchForm
                icon={<Magnifier height={25} />}
                placeholder="Znajdź quiz"
                value={search}
                onChange={(value) => setSearch(value)}
            />
            <PageContentWrapper>
                <Segment
                    path="/quizzes/:segment(done|undone)"
                    segmentConfigurations={[
                        {
                            segmentName: "Do wykonania",
                            paramName: "undone",
                        },
                        {
                            segmentName: "Wykonane",
                            paramName: "done",
                        },
                    ]}
                />
                <ListWrapper style={{ padding: "40px 16px 0px 16px" }}>
                    <WrapListContainer style={{ display: "block" }}>
                        <StyledOutlet animated={false}>
                            <Route
                                path="/quizzes/undone"
                                render={() => (
                                    <QuizListUnDone search={search} />
                                )}
                            />
                            <Route
                                path="/quizzes/done"
                                render={() => <QuizListDone search={search} />}
                            />
                            <Redirect
                                exact
                                from={"/quizzes"}
                                to={"/quizzes/undone"}
                                push={true}
                            />
                        </StyledOutlet>
                    </WrapListContainer>
                </ListWrapper>
            </PageContentWrapper>
        </>
    );
};
