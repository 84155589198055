import { useReducer, useState } from "react";
import SearchForm from "../../Controls/SearchForm/SearchForm";
import Loader from "../../Controls/Loader/Loader";
import { CocktailListItem } from "./CocktailListItem";
import { ListWrapper } from "../../Layout/List/ListWrapper";
import { useGetCocktailsQuery } from "../../../services/cocktail";
import { Magnifier } from "../../Icons/Magnifier";
import CocktailsFiltersModal, {
    NotFoundText,
} from "../CocktailsFiltersModal/CocktailsFiltersModal";
import { WrapListContainer } from "../../Dashboard/DashboardContent";
import {
    CocktailsContext,
    ContextType,
    cocktailsReducer,
    initialState,
} from "../../../context/CocktailsFilterContext";

export const CocktailList = () => {
    const [openModal, setOpenModal] = useState(false);
    const [state, dispatch] = useReducer(cocktailsReducer, initialState);

    const contextValue: ContextType = {
        state,
        dispatch,
    };

    const {
        currentData: cocktailsData,
        isFetching,
        isSuccess,
    } = useGetCocktailsQuery({
        search: state.search,
        glass_type_ids:
            state.glassTypes.length > 0 ? state.glassTypes : undefined,
        taste_ids: state.tastes.length > 0 ? state.tastes : undefined,
        ingredients_ids:
            state.ingredients.length > 0 ? state.ingredients : undefined,
        difficulty_level_ids:
            state.difficultyLevel.length > 0
                ? state.difficultyLevel
                : undefined,
        per_page: 100,
    });

    return (
        <CocktailsContext.Provider value={contextValue}>
            <CocktailsFiltersModal
                isOpen={openModal}
                setOpenModal={setOpenModal}
            />
            <SearchForm
                icon={<Magnifier height={25} />}
                placeholder="Znajdź koktail"
                value={state.search}
                onChange={(value) =>
                    dispatch({ type: "SEARCH", payload: value })
                }
                onClickButton={() => setOpenModal(true)}
            />
            <ListWrapper>
                {isFetching ? (
                    <Loader />
                ) : isSuccess ? (
                    cocktailsData.data.length === 0 ? (
                        <NotFoundText>
                            {state.search
                                ? `Nie znaleziono koktajlu o nazwie ${state.search}`
                                : `Nie znaleziono koktajlu`}
                        </NotFoundText>
                    ) : (
                        <WrapListContainer>
                            {cocktailsData.data.map((cocktail) => (
                                <CocktailListItem
                                    key={cocktail.id}
                                    item={cocktail}
                                />
                            ))}
                        </WrapListContainer>
                    )
                ) : null}
            </ListWrapper>
        </CocktailsContext.Provider>
    );
};
