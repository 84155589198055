/** Komponenty */
import {
    AiOutlineCalendar as CalendarIcon,
    AiOutlineClockCircle as ClockIcon,
} from "react-icons/ai";
import { Article } from "../../../types/Article";
import HeaderSectionWithButton, {
    StyledIonButton,
} from "../../Layout/Header/HeaderSectionWithButton";
import SlideSwiper from "../../Layout/Swipers/SlideSwiper";
import styled from "@emotion/styled";
import { IconTextList } from "../../Layout/IconTextList/IconTextList";
import { IconTextItem } from "../../Layout/IconTextList/IconTextItem";
import { formatDate, formatMinutes } from "../../../helpers/formathelpers";
import { CardSwiper } from "../../Layout/Swipers/CardSwiper";
import { useIonRouter } from "@ionic/react";
import { PageContentWrapper } from "../../Layout/Page/PageContentWrapper";

import { PostExcerpt } from "../../Layout/Post/PostExcerpt";
import { PostImageWrapper } from "../../Layout/Post/PostImageWrapper";
import { PostText } from "../../Layout/Post/PostText";
import { PostTitle } from "../../Layout/Post/PostTitle";
import { useGetArticlesQuery } from "../../../services/article";
import { useGetCocktailsQuery } from "../../../services/cocktail";
import Loader from "../../Controls/Loader/Loader";

import LargeCardSwiper from "../../Layout/Swipers/LargeCardSwiper";
import { devices } from "../../../theme/devices";
import { useCheckWindowWidth } from "../../../hooks/redux/useWindowWidth";

const StyledIonColSectionGray = styled("div")`
    background: ${({ theme }) => theme.colors.gray["160"]};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px 120px;
`;

const StyledPostContentWrapper = styled(PageContentWrapper)`
    padding: 41px 16px;
    @media only screen and (min-width: ${devices.tablet}) {
        display: flex;
    }
`;
const StyledPageContentWrapper = styled(PageContentWrapper)`
    padding: 24px 16px;
    @media only screen and (min-width: ${devices.tablet}) {
        border-right: 1px solid rgba(34, 45, 56, 0.16);
        padding: 24px 16px;
    }
`;
const LargeSwiperWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: sticky;
    top: 0;
    padding: 16px 16px;
`;

const HeaderWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: ${devices.tablet}) {
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
    }
`;

interface ArticleContentProps {
    article: Article;
}

const ArticleContent = (props: ArticleContentProps) => {
    const { article } = props;
    const isTabletSize = useCheckWindowWidth("tablet");
    const router = useIonRouter();

    const {
        currentData: articlesData,
        isFetching: isFetchingArticles,
        isSuccess: loadingArticlesSucceeded,
    } = useGetArticlesQuery({});

    const {
        currentData: cocktailsData,
        isFetching: isFetchingCocktails,
        isSuccess: loadingCocktailsSucceeded,
    } = useGetCocktailsQuery({});

    return (
        <>
            <PostImageWrapper backgroundUrl={article.photo_path} />
            <StyledPostContentWrapper>
                <StyledPageContentWrapper>
                    <HeaderWrapper>
                        <PostTitle>{article.title}</PostTitle>
                        <IconTextList
                            style={{
                                gap: "16px",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {article.time_read && (
                                <IconTextItem
                                    text={formatMinutes(article.time_read)}
                                    icon={<ClockIcon />}
                                />
                            )}
                            <IconTextItem
                                text={formatDate(article.created_at)}
                                icon={<CalendarIcon />}
                            />
                        </IconTextList>
                    </HeaderWrapper>
                    <PostExcerpt>{article.description_short}</PostExcerpt>
                    <PostText content={article.description} />
                </StyledPageContentWrapper>
                {isFetchingCocktails ? (
                    <Loader />
                ) : loadingCocktailsSucceeded ? (
                    !isTabletSize ? (
                        <LargeSwiperWrapper>
                            <HeaderSectionWithButton
                                header="Perfect serve"
                                padding={"5px 0 32px"}
                            />
                            <LargeCardSwiper
                                direction="vertical"
                                items={cocktailsData.data.map((cocktail) => ({
                                    id: cocktail.id,
                                    title: cocktail.name,
                                    subTitle: cocktail.taste,
                                    photo: cocktail.photo_path,
                                    onClick: () =>
                                        router.push(`/recipe/${cocktail.id}`),
                                }))}
                            />
                            <StyledIonButton
                                style={{ margin: "30px 0 0" }}
                                onClick={() => router.push("/recipes")}
                            >
                                Zobacz więcej
                            </StyledIonButton>
                        </LargeSwiperWrapper>
                    ) : null
                ) : null}
            </StyledPostContentWrapper>
            <StyledIonColSectionGray>
                <HeaderSectionWithButton
                    header="Podobne artykuły"
                    url="/knowledge-base"
                />

                {isFetchingArticles ? (
                    <Loader />
                ) : loadingArticlesSucceeded ? (
                    <CardSwiper
                        cardVariant="square"
                        items={articlesData.data
                            .filter(
                                (relatedArticle) =>
                                    relatedArticle.id !== article.id
                            )
                            .map((relatedArticle) => ({
                                id: relatedArticle.id,
                                title: relatedArticle.title,
                                subTitle: relatedArticle.category?.name,
                                photo: relatedArticle.photo_path,
                                priority: relatedArticle.is_singled_out,
                                onClick: () =>
                                    router.push(
                                        `/article/${relatedArticle.id}`
                                    ),
                                iconTextItem: {
                                    text: relatedArticle.time_read
                                        ? formatMinutes(
                                              relatedArticle.time_read
                                          )
                                        : null,
                                    icon: <ClockIcon />,
                                },
                            }))}
                    />
                ) : null}

                {isFetchingCocktails ? (
                    <Loader />
                ) : loadingCocktailsSucceeded && !isFetchingCocktails ? (
                    isTabletSize ? (
                        <>
                            <HeaderSectionWithButton
                                header="Perfect serve"
                                buttonContent="Więcej koktajli"
                                url="/recipes"
                            />
                            <SlideSwiper
                                items={cocktailsData.data.map((cocktail) => ({
                                    id: cocktail.id,
                                    title: cocktail.name,
                                    subTitle: cocktail.taste,
                                    photo: cocktail.photo_path,
                                    onClick: () =>
                                        router.push(`/recipe/${cocktail.id}`),
                                }))}
                            />
                        </>
                    ) : null
                ) : null}
            </StyledIonColSectionGray>
        </>
    );
};

export default ArticleContent;
