import React from "react";
import StyledSvg from "./StyledSvg";

interface IconGlassProps {
    height?: number;
    color?: string;
    display?: string;
}
const IconGlass = (props: IconGlassProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                className="stroke"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.07143 22H12.5M17.9286 22H12.5M12.5 22V16.8235M12.5 16.8235L20.5 9.91176V7.45588M12.5 16.8235L4.5 9.91176V7.45588M4.5 7.45588V3H20.5V7.45588M4.5 7.45588H20.5"
                    className="stroke"
                />
            </svg>
        </StyledSvg>
    );
};

const IconGlassFull = (props: IconGlassProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                width="19"
                height="21"
                viewBox="0 0 19 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className="stroke fill"
                    d="M1.5 1H17.5V5.45588V7.91176L9.5 14.8235L1.5 7.91176V5.45588V1Z"
                />
                <path
                    className="stroke fill"
                    d="M4.07143 20H9.5M14.9286 20H9.5M9.5 20V14.8235M9.5 14.8235L17.5 7.91176V5.45588M9.5 14.8235L1.5 7.91176V5.45588M1.5 5.45588V1H17.5V5.45588M1.5 5.45588H17.5"
                />
            </svg>
        </StyledSvg>
    );
};
export { IconGlass, IconGlassFull };
