import styled from "@emotion/styled";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import RoundedProgressBar from "../../Controls/ProgressBar/RoundedProgressBar";
import { useTheme } from "@emotion/react";
import { devices } from "../../../theme/devices";

const Container = styled("div")`
    padding: 0 16px;
`;
const Wrapper = styled("div")<{ background?: string; inQuizSummary?: boolean }>`
    background-color: ${({ theme, background }) =>
        background ?? theme.colors.primary.carolinaBlue};
    padding: 24px 16px;
    min-width: 343px;
    position: absolute;
    z-index: 1;
    top: ${({ inQuizSummary }) => (inQuizSummary ? "400px" : "200px")};
    left: 50%;
    transform: translate(-50%, 0);
    @media only screen and (min-width: ${devices.tablet}) {
        top: ${({ inQuizSummary }) => (inQuizSummary ? "400px" : "80px")};
        right: 0;
        left: ${({ inQuizSummary }) => (inQuizSummary ? "50%" : "auto")};
        transform: ${({ inQuizSummary }) =>
            inQuizSummary ? " translate(50, 0)" : "translate(0, 0)"};
        margin-right: 16px;
    }
`;
const Headline = styled("h5")`
    ${({ theme }) => theme.text.h5}
    margin: 0;
    color: ${({ theme }) => theme.colors.white["1000"]};
`;
const Text = styled("p")<{
    text?: string;
}>`
    ${({ theme }) => theme.text.paragraph.regular}
    margin: 0;
    color: ${({ theme }) => theme.colors.white["640"]};
    ${({ text }) =>
        text &&
        `
            margin-top: 4px;
        `}
`;
const Divider = styled("div")`
    width: 1px;
    height: 100%;
    min-height: 80px;
    background: ${({ theme }) => theme.colors.white["160"]};
`;

const StyledRow = styled(IonRow)`
    align-items: center;
`;

interface ProgressCardProps {
    headline: string;
    value: number;
    text?: string;
    variant?: "blue" | "green" | "red";
    inQuizSummary?: boolean;
}

export const ProgressCard = (props: ProgressCardProps) => {
    const { headline, text, value, variant = "blue", inQuizSummary } = props;
    const theme = useTheme();

    const variantColors = {
        blue: {
            background: theme.colors.primary.carolinaBlue,
            foreground: theme.colors.primary.aliceBlue,
            text: theme.colors.white["1000"],
        },
        green: {
            background: theme.colors.extras.shamrockGreen,
            foreground: theme.colors.white["1000"],
            text: theme.colors.white["1000"],
        },
        red: {
            background: theme.colors.extras.internationalOrangeGoldenGateBridge,
            foreground: theme.colors.white["1000"],
            text: theme.colors.white["1000"],
        },
    };

    const colors = variantColors[variant];

    return (
        <Container>
            <Wrapper
                background={colors.background}
                inQuizSummary={inQuizSummary}
            >
                <IonGrid>
                    <StyledRow>
                        <IonCol>
                            <Headline>{headline}</Headline>
                            <Text text={text}>{text}</Text>
                        </IonCol>
                        <IonCol size="1">
                            <Divider />
                        </IonCol>
                        <IonCol size="auto">
                            <RoundedProgressBar
                                value={value}
                                size="regular"
                                backgroundColor={colors.background}
                                foregroundColor={colors.foreground}
                                textColor={colors.text}
                            />
                        </IonCol>
                    </StyledRow>
                </IonGrid>
            </Wrapper>
        </Container>
    );
};
