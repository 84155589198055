import { useReducer, useRef } from "react";
import Loader from "../../Controls/Loader/Loader";
// import { ArticleListItem } from "./ArticleListItem";
import { IonCol, IonGrid, IonInfiniteScroll, IonRow } from "@ionic/react";
import { ListWrapper } from "../../Layout/List/ListWrapper";
import SearchForm from "../../Controls/SearchForm/SearchForm";
import { Training } from "../../../types/Training";
import { useInfiniteQuery } from "../../../hooks/useInfiniteQuery";
// import ArticlesFiltersModal, {
//     NotFoundText,
// } from "../ArticleFiltersModal/ArticlesFiltersModal";
import { Magnifier } from "../../Icons/Magnifier";
import {
    TrainingContext,
    ContextType,
    trainingReducer,
    initialState,
} from "../../../context/TrainingFiltersContext";
import { PullDownIndicator } from "../../Controls/PullDownIndicator/PullDownIndicator";
import { useLazyGetTrainingQuery } from "../../../services/training";
import { NotFoundText } from "../../Articles/ArticleFiltersModal/ArticlesFiltersModal";
import { TrainingListItem } from "./TrainingListItem";

const TrainingList = () => {
    // const [openModal, setOpenModal] = useState(false);
    const [state, dispatch] = useReducer(trainingReducer, initialState);

    const contextValue: ContextType = {
        state,
        dispatch,
    };

    const { combinedData, isLoading, isFetching, readMore, hasMore } =
        useInfiniteQuery<Training>(useLazyGetTrainingQuery, {
            search: state.search,
            per_page: 10,
        });

    const scrollRef = useRef<HTMLDivElement>(null);

    return (
        <TrainingContext.Provider value={contextValue}>
            {/* <TrainingFiltersModal
                isOpen={openModal}
                setOpenModal={setOpenModal}
            /> */}
            <SearchForm
                icon={<Magnifier height={25} />}
                placeholder="Znajdź szkolenie"
                value={state.search}
                onChange={(value) =>
                    dispatch({ type: "SEARCH", payload: value })
                }
                // onClickButton={() => setOpenModal(true)}
            />
            <ListWrapper ref={scrollRef}>
                {isLoading ? (
                    <Loader />
                ) : combinedData.length === 0 ? (
                    <NotFoundText>
                        {state.search
                            ? `Nie znaleziono szkolenia o nazwie ${state.search}`
                            : `Nie znaleziono szkolenia`}
                    </NotFoundText>
                ) : (
                    <>
                        <IonGrid>
                            <IonRow>
                                {combinedData.map((training) => {
                                    return (
                                        <IonCol
                                            key={training.id}
                                            size="6"
                                            size-md="3"
                                        >
                                            <TrainingListItem item={training} />
                                        </IonCol>
                                    );
                                })}
                            </IonRow>
                        </IonGrid>
                        <IonInfiniteScroll
                            onIonInfinite={(ev) => {
                                readMore().then(() => {
                                    ev.target.complete();
                                });
                            }}
                            disabled={!hasMore}
                        >
                            {isFetching ? <Loader /> : <PullDownIndicator />}
                        </IonInfiniteScroll>
                    </>
                )}
            </ListWrapper>
        </TrainingContext.Provider>
    );
};
export default TrainingList;
