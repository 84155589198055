import { IonLabel, SegmentCustomEvent, useIonRouter } from "@ionic/react";
import {
    StyledIonSegment,
    StyledIonSegmentButton,
} from "../../Quizzes/QuizList/QuizList";
import { useMemo } from "react";
import { matchPath } from "react-router";

type SegmentConfiguration = {
    segmentName: string;
    paramName: string;
};
interface SegmentProps {
    path: string;
    segmentConfigurations: SegmentConfiguration[];
}

const Segment = (props: SegmentProps) => {
    const { path, segmentConfigurations } = props;
    const router = useIonRouter();
    const segment = useMemo(
        () =>
            matchPath<{ segment: string }>(router.routeInfo.pathname, {
                path: path,
            })?.params.segment,
        [path, router.routeInfo.pathname]
    );

    const handleSegmentChange = (e: SegmentCustomEvent) => {
        const newSegment = e.target.value;
        if (newSegment) {
            router.push(newSegment);
        }
    };
    return (
        <StyledIonSegment
            key={String(!!segment)}
            // The above was necessary to force rerender IonSegment
            // when segment value changes from undefined to "undone" on redirect
            value={segment}
            onIonChange={handleSegmentChange}
        >
            {segmentConfigurations.map((segmentConfiguration) => (
                <StyledIonSegmentButton
                    key={segmentConfiguration.paramName}
                    value={segmentConfiguration.paramName}
                >
                    <IonLabel>{segmentConfiguration.segmentName}</IonLabel>
                </StyledIonSegmentButton>
            ))}
        </StyledIonSegment>
    );
};

export default Segment;
