import React from "react";
import styled from "@emotion/styled";

const ProgressBarWrapper = styled("div")<{
    size: "regular" | "small";
}>`
    position: relative;
    width: ${({ size }) => {
        switch (size) {
            case "regular":
                return "80px";
            case "small":
                return "48px";
        }
    }};
    height: ${({ size }) => {
        switch (size) {
            case "regular":
                return "80px";
            case "small":
                return "48px";
        }
    }};
`;

const ProgressBarRing = styled("div")<{
    value: number;
    backgroundColor: string;
    foregroundColor: string;
}>`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 100%;
    &:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;
        border-radius: 100%;
        border: 2px solid ${({ foregroundColor }) => foregroundColor};
        opacity: 0.25;
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 100%;
        background: radial-gradient(
                closest-side,
                ${({ backgroundColor }) => backgroundColor} calc(100% - 5px),
                transparent 0 99.9%
            ),
            conic-gradient(
                ${({ foregroundColor }) => foregroundColor}
                    calc(${({ value }) => value} * 100%),
                rgba(255, 255, 255, 0) 0
            );
    }
`;

const ProgressBarCounter = styled("p")<{
    size: "regular" | "small";
    textColor: string;
}>`
    ${({ size, theme }) => {
        switch (size) {
            case "regular":
                return theme.text.h4;
            case "small":
                return theme.text.h6;
        }
    }};
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ size }) => {
        switch (size) {
            case "regular":
                return "80px";
            case "small":
                return "48px";
        }
    }};
    height: ${({ size }) => {
        switch (size) {
            case "regular":
                return "80px";
            case "small":
                return "48px";
        }
    }};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 8;
    color: ${({ textColor }) => textColor};
    margin: 0;
    padding: 0;
`;

interface RoundedProgressBarProps {
    value: number;
    size: "regular" | "small";
    backgroundColor: string;
    foregroundColor: string;
    textColor: string;
}

const RoundedProgressBar = (props: RoundedProgressBarProps) => {
    const { value, size, backgroundColor, foregroundColor, textColor } = props;

    return (
        <ProgressBarWrapper size={size}>
            <ProgressBarRing
                value={value}
                backgroundColor={backgroundColor}
                foregroundColor={foregroundColor}
            >
                <ProgressBarCounter size={size} textColor={textColor}>
                    {Math.floor(value * 100)}%
                </ProgressBarCounter>
            </ProgressBarRing>
        </ProgressBarWrapper>
    );
};

export default RoundedProgressBar;
