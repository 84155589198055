import { ApiRequestMultipleItemsParams } from "../types/Api/ApiRequestParams";
import {
    ApiResponseMultipleItems,
    ApiResponseSingleItem,
} from "../types/Api/ApiResponse";
import { Cocktail } from "../types/Cocktail";
import { api } from "./api";

export const cocktailApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCocktail: build.query<ApiResponseSingleItem<Cocktail>, number>({
            query: (id) => `/cocktails/${id}`,
        }),
        getCocktails: build.query<
            ApiResponseMultipleItems<Cocktail>,
            ApiRequestMultipleItemsParams<Cocktail> & {
                glass_type_ids?: number[];
                taste_ids?: number[];
                ingredients_ids?: number[];
                difficulty_level_ids?: number[];
            }
        >({
            query: (params) => ({ url: `/cocktails`, params }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetCocktailQuery,
    useGetCocktailsQuery,
    useLazyGetCocktailQuery,
    useLazyGetCocktailsQuery,
} = cocktailApi;
