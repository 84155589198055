import { createContext } from "react";

export interface State {
    search: string;
}
type Action = { type: "SEARCH"; payload: string };

export interface ContextType {
    state: State;
    dispatch: React.Dispatch<Action>;
}

export const initialState: State = {
    search: "",
};

export const TrainingContext = createContext<ContextType | null>(null);

export const trainingReducer = (state: State, action: Action) => {
    switch (action.type) {
        case "SEARCH":
            return {
                ...state,
                search: action.payload,
            };
        default:
            return state;
    }
};
