export const ExitIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 2L8 8M8 8L2 2M8 8L2 14M8 8L14 14"
                stroke="#FFFFFF"
                stroke-width="1.6"
            />
        </svg>
    );
};
