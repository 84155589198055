import { useAppSelector } from "../../../hooks/redux/useAppSelector";
import { QuizQuestions } from "./QuizQuestions";
import { QuizStart } from "./QuizStart";

export const QuizContent = () => {
    const quizState = useAppSelector((state) => state.quiz);

    switch (quizState.state) {
        case "inactive":
            return <QuizStart />;
        case "active":
        case "finished":
        case "sent":
            return <QuizQuestions />;
        default:
            return null;
    }
};
