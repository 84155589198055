import styled from "@emotion/styled";
import placeholder from "./../../../../assets/img/placeholder.svg";

export const ListItemImageWrapper = styled("div")<{
    backgroundUrl?: string;
}>`
    position: relative;
    width: 48px;
    height: 48px;
    background-image: url(${({ backgroundUrl }) =>
        backgroundUrl ?? placeholder});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%;
`;
