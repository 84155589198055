import styled from "@emotion/styled";
import { IonButton, useIonRouter } from "@ionic/react";

const HeaderBox = styled("div")<{
    padding?: string;
}>`
    width: 100%;
    color: ${({ theme }) => theme.colors.gray["1000"]};
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${({ padding }) => (padding ? padding : `40px 0 16px`)};
    background-image: none;
`;
const Header = styled("h5")`
    ${({ theme }) => theme.text.h5}
    margin: 0;
`;

export const StyledIonButton = styled(IonButton)`
    border-radius: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    color: ${({ theme }) => theme.colors.gray["1000"]};
    border: 1px solid rgba(34, 45, 56, 0.16);
    text-transform: none;
    font-size: 12px;
    margin: 0;
    outline: none;
    font-weight: 700;
    --background: transparent;
`;

interface HeaderSectionWithButtonProps {
    header: string;
    buttonContent?: string;
    url?: string;
    padding?: string;
}

const HeaderSectionWithButton = (props: HeaderSectionWithButtonProps) => {
    const { header, buttonContent, url, padding } = props;
    const router = useIonRouter();

    const handleNavigate = () => {
        if (url) {
            router.push(url);
        }
    };

    return (
        <HeaderBox padding={padding}>
            <Header>{header}</Header>
            {buttonContent && (
                <StyledIonButton
                    onClick={handleNavigate}
                    type="button"
                    fill="clear"
                >
                    {buttonContent}
                </StyledIonButton>
            )}
        </HeaderBox>
    );
};
export default HeaderSectionWithButton;
