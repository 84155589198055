import styled from "@emotion/styled";
import { IonCheckbox } from "@ionic/react";

export const StyledIonCheckbox = styled(IonCheckbox)`
    margin-bottom: 24px;
    ::part(container) {
        border-radius: 0;
        border: 1px solid ${({ theme }) => theme.colors.primary.gunmetal["400"]};
        background: ${({ theme }) => theme.colors.white["1000"]};
        color: black;
    }
    &.checkbox-checked::part(container) {
        background: ${({ theme }) => theme.colors.primary.aliceBlue};
        border: 1px solid
            ${({ theme }) => theme.colors.primary.gunmetal["1000"]};
    }
    ::part(mark) {
        stroke: black;
    }
`;
