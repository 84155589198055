import { AiOutlineClockCircle as ClockIcon } from "react-icons/ai";
import SlideSwiper from "../Layout/Swipers/SlideSwiper";
import styled from "@emotion/styled";
import HeaderSectionWithButton from "../Layout/Header/HeaderSectionWithButton";
import { QuizListItem } from "../Quizzes/QuizList/QuizListItem";
import { CardSwiper } from "../Layout/Swipers/CardSwiper";
import { useIonRouter } from "@ionic/react";
import { formatMinutes } from "../../helpers/formathelpers";
import { useGetArticlesQuery } from "../../services/article";
import { useGetCocktailsQuery } from "../../services/cocktail";
import { useGetMainScoreQuery, useGetQuizzesQuery } from "../../services/quiz";
import Loader from "../Controls/Loader/Loader";
import { ProgressCard } from "../Layout/ProgressCard/ProgressCard";
import { PostContentWrapper } from "../Layout/Post/PostContentWrapper";
import LargeCardSwiper from "../Layout/Swipers/LargeCardSwiper";
import { devices } from "../../theme/devices";
import { useCheckWindowWidth } from "../../hooks/redux/useWindowWidth";

export const WrapListContainer = styled("div")`
    background: ${({ theme }) => theme.colors.white["1000"]};
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 0;
    padding-bottom: 30px;
    @media only screen and (min-width: ${devices.tablet}) {
        flex-direction: row;
    }
`;
const StyledIonColSectionGray = styled("div")`
    background: ${({ theme }) => theme.colors.gray["160"]};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px 120px;
`;

const StyledIonColSectionWhite = styled("div")`
    background: ${({ theme }) => theme.colors.white["1000"]};
    width: 100%;
    padding: 0 16px;
`;

const Dashboard = () => {
    const router = useIonRouter();
    const isTabletSize = useCheckWindowWidth("tablet");

    const {
        data: articlesData,
        isLoading: isLoadingArticles,
        isSuccess: loadingArticlesSucceeded,
    } = useGetArticlesQuery({});

    const {
        data: cocktailsData,
        isLoading: isLoadingCocktails,
        isSuccess: loadingCocktailsSucceeded,
    } = useGetCocktailsQuery({});

    const {
        data: quizzesDoneData,
        isLoading: isLoadingQuizzesDone,
        isSuccess: loadingQuizzesDoneSucceeded,
    } = useGetQuizzesQuery({ done: true });

    const {
        data: quizzesToDoData,
        isLoading: isLoadingQuizzesToDo,
        isSuccess: loadingQuizzesToDoSucceeded,
    } = useGetQuizzesQuery({ done: false });

    const {
        data: mainScore,
        isLoading: isLoadingMainScore,
        isSuccess: loadingMainScoreSucceeded,
    } = useGetMainScoreQuery({});

    return (
        <>
            <PostContentWrapper
                slim
                marginTop={66}
                before={
                    isLoadingMainScore ? (
                        <Loader />
                    ) : loadingMainScoreSucceeded ? (
                        <ProgressCard
                            headline="Obecny wynik Twoich quizów"
                            text={
                                mainScore / 100 < 60
                                    ? "Spokojnie, nie poddawaj się!"
                                    : "Idzie Ci naprawdę dobrze!"
                            }
                            value={mainScore / 100}
                        />
                    ) : null
                }
            >
                <StyledIonColSectionWhite>
                    <HeaderSectionWithButton
                        header="Baza wiedzy"
                        buttonContent="Więcej atykułów"
                        url="/knowledge-base"
                    />
                    {isLoadingArticles ? (
                        <Loader />
                    ) : loadingArticlesSucceeded ? (
                        <CardSwiper
                            cardVariant="square"
                            items={articlesData.data.map((article) => ({
                                id: article.id,
                                title: article.title,
                                subTitle: article.category?.name,
                                photo: article.photo_path,
                                onClick: () =>
                                    router.push(`/article/${article.id}`),
                                iconTextItem: {
                                    text: article.time_read
                                        ? formatMinutes(article.time_read)
                                        : null,
                                    icon: <ClockIcon />,
                                },
                            }))}
                        />
                    ) : null}

                    <HeaderSectionWithButton
                        header="Quizy do wykonania"
                        buttonContent="Zobacz wszystkie"
                        url="/quizzes/undone"
                    />

                    {isLoadingQuizzesToDo ? (
                        <Loader />
                    ) : loadingQuizzesToDoSucceeded ? (
                        <CardSwiper
                            items={quizzesToDoData.data.map((quiz) => ({
                                id: quiz.id,
                                title: quiz.name,
                                subTitle: quiz.taste,
                                photo: quiz.photo_path,
                                onClick: () => router.push(`/quiz/${quiz.id}`),
                                priority: quiz.is_singled_out,
                                content: "Rozpocznij quiz",
                            }))}
                            cardVariant="round"
                        />
                    ) : null}

                    <HeaderSectionWithButton
                        header="Quizy wykonane"
                        buttonContent="Zobacz wszystkie"
                        url="/quizzes/done"
                    />

                    {isLoadingQuizzesDone ? (
                        <Loader />
                    ) : loadingQuizzesDoneSucceeded ? (
                        <WrapListContainer>
                            {quizzesDoneData.data.map((quiz) => (
                                <QuizListItem
                                    key={quiz.id}
                                    item={quiz}
                                    isDone={true}
                                />
                            ))}
                        </WrapListContainer>
                    ) : null}
                </StyledIonColSectionWhite>
                <StyledIonColSectionGray>
                    <HeaderSectionWithButton
                        header="Koktajle miesiąca"
                        buttonContent="Więcej koktajli"
                        url="/recipes"
                    />

                    {isLoadingCocktails ? (
                        <Loader />
                    ) : loadingCocktailsSucceeded ? (
                        isTabletSize ? (
                            <SlideSwiper
                                items={cocktailsData.data.map((cocktail) => ({
                                    id: cocktail.id,
                                    title: cocktail.name,
                                    subTitle: cocktail.taste,
                                    photo: cocktail.photo_path,
                                    onClick: () =>
                                        router.push(`/recipe/${cocktail.id}`),
                                }))}
                            />
                        ) : (
                            <LargeCardSwiper
                                direction="horizontal"
                                items={cocktailsData.data.map((cocktail) => ({
                                    id: cocktail.id,
                                    title: cocktail.name,
                                    subTitle: cocktail.taste,
                                    photo: cocktail.photo_path,
                                    onClick: () =>
                                        router.push(`/recipe/${cocktail.id}`),
                                }))}
                            />
                        )
                    ) : null}
                </StyledIonColSectionGray>
            </PostContentWrapper>
        </>
    );
};

export default Dashboard;
