import { useIonRouter } from "@ionic/react";
import React from "react";

import ButtonIcon from "./ButtonIcon";
import { IconChevronLeft } from "../../Icons/IconChevronLeft";

export const GoBackButton = () => {
    const router = useIonRouter();

    const handleButtonClick = () => {
        router.goBack();
    };

    return (
        <ButtonIcon color="light" onClick={handleButtonClick}>
            <IconChevronLeft />
        </ButtonIcon>
    );
};
