import { ApiRequestMultipleItemsParams } from "../types/Api/ApiRequestParams";
import { ApiResponseMultipleItems } from "../types/Api/ApiResponse";
import { CocktailIngredients } from "../types/Cocktail";
import { api } from "./api";

export const ingredientApi = api.injectEndpoints({
    endpoints: (build) => ({
        getIngredients: build.query<
            ApiResponseMultipleItems<CocktailIngredients>,
            ApiRequestMultipleItemsParams<CocktailIngredients>
        >({
            query: (params) => ({ url: `/ingredients`, params }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetIngredientsQuery, useLazyGetIngredientsQuery } =
    ingredientApi;
