import styled from "@emotion/styled";
import { IonItem, IonLabel, IonRadio } from "@ionic/react";
import { ReactComponent as IconSuccess } from "../../../assets/icons/radio-success.svg";
import { ReactComponent as IconError } from "../../../assets/icons/radio-error.svg";

const RadioContainer = styled(IonItem)<{
    radioDisabled?: boolean;
    indicator?: "success" | "error";
}>`
    width: 100%;
    margin: 8px 0;
    border: 1.6px solid
        ${({ indicator, theme }) =>
            indicator === "error"
                ? theme.colors.extras.internationalOrangeGoldenGateBridge
                : indicator === "success"
                ? theme.colors.extras.shamrockGreen
                : "transparent"};

    ${({ indicator, theme }) =>
        indicator && {
            color:
                indicator === "error"
                    ? theme.colors.extras.internationalOrangeGoldenGateBridge
                    : indicator === "success"
                    ? theme.colors.extras.shamrockGreen
                    : "unset",
        }}\
    &::part(native) {
        background: ${({ theme, radioDisabled, indicator }) =>
            radioDisabled
                ? "rgba(232, 238, 242, 0.32)"
                : indicator !== undefined
                ? theme.colors.white["1000"]
                : theme.colors.primary.aliceBlue};
        padding: 0;
    }

    &.item-radio-checked {
        border-color: ${({ theme }) => theme.colors.gray["1000"]};

        &::part(native) {
            background: ${({ theme }) => theme.colors.white["1000"]};
        }
    }
`;

const StyledIonLabel = styled(IonLabel)`
    white-space: normal;
    margin-left: 16px;
    ${({ theme }) => theme.text.paragraph.big};
`;

const StyledIonRadio = styled(IonRadio)`
    text-overflow: visible;
    margin-left: 20px;

    &::part(container) {
        width: 24px;
        height: 24px;
        background: white;
        border-radius: 50%;
        border: 1.6px solid ${({ theme }) => theme.colors.gray["1000"]};
    }

    &.radio-checked::part(container) {
        border-color: black;
    }

    &.radio-checked::part(mark) {
        width: 8px;
        height: 8px;
        background: black;
        border-radius: 50%;
        border: none;
    }
`;

const IconWrapper = styled("div")`
    margin-left: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
`;

type RadioButtonProps = {
    label: string;
    value?: number;
    disabled?: boolean;
    indicator?: "success" | "error";
};

const RadioButton = (props: RadioButtonProps) => {
    const { value, label, disabled, indicator } = props;

    return (
        <RadioContainer
            lines="none"
            radioDisabled={disabled}
            indicator={indicator}
        >
            {indicator ? (
                <IconWrapper>
                    {indicator === "error" ? (
                        <IconError />
                    ) : indicator === "success" ? (
                        <IconSuccess />
                    ) : null}
                </IconWrapper>
            ) : (
                <StyledIonRadio
                    legacy={true}
                    value={value}
                    disabled={disabled}
                />
            )}
            <StyledIonLabel text-wrap>{label}</StyledIonLabel>
        </RadioContainer>
    );
};

export default RadioButton;
