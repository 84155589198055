import { IonContent, IonPage } from "@ionic/react";
import { QuizList } from "../../components/Quizzes/QuizList/QuizList";
import HeaderMainComplex from "../../components/Layout/Header/HeaderMainComplex";

export const QuizListPage = () => {
    return (
        <IonPage>
            <IonContent fullscreen>
                <HeaderMainComplex headline="Quizy" />
                <QuizList />
            </IonContent>
        </IonPage>
    );
};
