import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

const NavItem = styled("div")`
    height: 64px;
    min-width: 64px;
    color: ${({ theme }) => theme.colors.white["1000"]};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    ${({ theme }) => theme.text.paragraph.tiny}
`;
const NavIconWrapper = styled("div")`
    width: 100%;
    height: 32px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 20px;
    padding-bottom: 4px;
`;
const NavTextWrapper = styled("div")<{
    active: boolean;
}>`
    width: auto;
    display: inline-block;
    margin: auto;
    text-align: center;
    color: ${({ theme }) => theme.colors.white["640"]};
    padding-bottom: 8px;
    transition: color 0.2s ease-in-out;
    ${({ theme, active }) =>
        active &&
        `
            color: transparent;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                width: 24px;
                height: 2px;
                top: calc(50% - 5px);
                left: 50%;
                transform: translate(-50%, 0);
                background: ${theme.colors.primary.oldLace};
            }
        `}
`;

interface MainNavItemProps {
    name: string;
    ActiveIcon: React.FC<{
        height?: number;
        color?: string;
        display?: string;
    }>;
    InactiveIcon: React.FC<{
        height?: number;
        color?: string;
        display?: string;
    }>;
    active: boolean;
}

const MainNavItem = (props: MainNavItemProps) => {
    const { name, active, ActiveIcon, InactiveIcon } = props;

    const theme = useTheme();

    return (
        <NavItem>
            <NavIconWrapper>
                {active ? (
                    <ActiveIcon
                        color={theme.colors.white["1000"]}
                        height={20}
                    />
                ) : (
                    <InactiveIcon
                        color={theme.colors.white["1000"]}
                        height={20}
                    />
                )}
            </NavIconWrapper>
            <NavTextWrapper active={active}>{name}</NavTextWrapper>
        </NavItem>
    );
};

export default MainNavItem;
