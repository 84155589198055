import React from "react";
import styled from "@emotion/styled";

const ButtonStyle = styled("button")`
    ${({ theme }) => theme.text.paragraph.regular}
    font-weight: 700;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.gray["160"]};
    width: 100%;
    padding: 7px 18px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.white["1000"]};
    color: ${({ theme }) => theme.colors.gray["1000"]};
    font-size: 12px;
    gap: 7px;
    svg {
        max-height: 14px;
    }
`;

interface ButtonProps {
    text: string;
    icon?: React.ReactNode;
    onClick: React.ReactEventHandler;
}

const SimplyButton = (props: ButtonProps) => {
    const { text, onClick, icon } = props;

    return (
        <ButtonStyle onClick={onClick}>
            {icon && icon} {text && text}
        </ButtonStyle>
    );
};

export default SimplyButton;
