const themeColors = {
    primary: {
        gunmetal: {
            "1000": "#222D38",
            "800": "rgba(34,45,56,.8)",
            "640": "rgba(34,45,56,.64)",
            "400": "rgba(34,45,56,.4)",
            "160": "rgba(34,45,56,.16)",
        },
        oxfordBlue: "#0C294E",
        dodgerBlue: "#2D8EFB",
        carolinaBlue: "#5FA4D0",
        oldLace: "#FBF4E7",
        beauBlue: "#C7D3DD",
        aliceBlue: "#E8EEF2",
    },
    raisinBlack: {
        "900": "#2D303B",
        "800": "#454751",
        "700": "#5C5E66",
        "600": "#73757C",
        "500": "#8B8C92",
        "400": "#A2A3A8",
        "300": "#B9BABE",
        "200": "#D0D1D3",
        "100": "#E8E8E9",
    },
    gray: {
        "1000": "#292D32",
        "500": "#DCDFE1",
        "300": " #D7DFE5",
        "640": "rgba(34,45,56,0.64)",
        "240": "rgba(34,45,56,0.24)",
        "160": "#E8EEF2",
    },
    white: {
        "1000": "#FFFFFF",
        "640": "rgba(255,255,255,.64)",
        "240": "rgba(255,255,255,.24)",
        "160": "rgba(255,255,255,.16)",
    },
    extras: {
        shamrockGreen: "#329F5B",
        internationalOrangeGoldenGateBridge: "#C03221",
    },
    gradient: {
        "dark-to-bottom":
            "linear-gradient(to bottom, #222D38 0%, #222D38 50%, #FFFFFF 50%, #FFFFFF 100%)",
    },
};

export default themeColors;
