import themeColors from "./themeColors";

const themeForm = {
    textInput: {
        background: themeColors.white["1000"],
        borderColor: themeColors.gray["160"],
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "0",
        color: themeColors.gray["1000"],
        height: "56px",
        paddingLeft: "16px",
        paddingRight: "16px",
        width: "100%",
        transition: "border .2s ease-in-out",
        "&:focus": {
            outline: "0",
            border: `1px solid ${themeColors.gray["640"]}`,
        },
    },
};

export default themeForm;
