import styled from "@emotion/styled";
import { IonInput } from "@ionic/react";
import { ReactElement } from "react";

const InputWrapper = styled("div")`
    position: relative;
    width: 100%;
`;
const IconBox = styled("div")`
    position: absolute;
    left: 0;
    height: 56px;
    width: 56px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InputStyled = styled(IonInput)<{
    marginBottom?: number;
    icon?: ReactElement;
}>`
    ${({ theme }) => theme.text.paragraph.regular}
    --background: ${({ theme }) => theme.colors.white["1000"]};
    border: 1px solid ${({ theme }) => theme.colors.gray["160"]};
    --color: ${({ theme }) => theme.colors.gray["1000"]};
    --padding-top: 18px;
    --padding-bottom: 18px;
    --padding-start: ${({ icon }) => (icon ? "56px" : "16px")};
    --padding-end: 16px;
    transition: border 0.2s ease-in-out;
    --border-width: 1px;
    --highlight-color-focused: ${({ theme }) => theme.colors.gray["640"]};
    margin-bottom: ${({ marginBottom }) =>
        marginBottom ? `${marginBottom}px` : 0};
    &:focus {
        border: 1px solid ${({ theme }) => theme.colors.gray["640"]};
    }
`;

type InputProps =
    | {
          type: "text";
          placeholder?: string;
          value: string | undefined;
          onChange: (value: string) => void;
          marginBottom?: number;
          icon?: ReactElement;
      }
    | {
          type: "number";
          placeholder?: string;
          value: number | undefined | null;
          onChange: (value: number) => void;
          marginBottom?: number;
          icon?: ReactElement;
      };

const Input = (props: InputProps) => {
    const { placeholder, value, onChange, type, marginBottom, icon } = props;

    return (
        <InputWrapper>
            {icon && <IconBox>{icon}</IconBox>}
            <InputStyled
                icon={icon}
                marginBottom={marginBottom}
                placeholder={placeholder}
                value={value}
                type={type}
                onIonInput={(event) => {
                    switch (type) {
                        case "number":
                            onChange(Number(event.target.value) || 0);
                            return;
                        case "text":
                            onChange(event.target.value as string);
                            return;
                    }
                }}
            />
        </InputWrapper>
    );
};

export default Input;
