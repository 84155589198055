import ButtonIcon from "../../Controls/Buttons/ButtonIcon";
import { IonModal } from "@ionic/react";
import styled from "@emotion/styled";
import { useState } from "react";
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg";
import Alcohol from "./ArticleFilters/Alcohol";
import { HeaderMain, HeaderTitle } from "../../Layout/Header/HeaderMainStyles";
import { devices } from "../../../theme/devices";
import { useCheckWindowWidth } from "../../../hooks/redux/useWindowWidth";
import { useGetCategoriesQuery } from "../../../services/category";
import {
    displayChoices,
    displayNameChoices,
} from "../../../helpers/filtersHelpers";
import { useArticlesContext } from "../../../hooks/useAppContext";
import { ExitIcon } from "../../Icons/ExitIcon";
import { IconChevronLeft } from "../../Icons/IconChevronLeft";

export const StyledModal = styled(IonModal)`
    margin-left: auto;
    margin-right: 0;
    @media only screen and (min-width: ${devices.tablet}) {
        max-width: 430px;
    }
`;

export const StyledOverlay = styled("div")`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    z-index: 2000;
`;

export const Wrapper = styled("div")`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 16px 95px;
    align-items: flex-start;
    overflow: scroll;
`;
export const NotFoundText = styled("p")`
    width: 100%;
    text-align: center;
`;
export const FilterContainer = styled("div")`
    width: 100%;
`;

export const FilterItemBox = styled("div")`
    display: flex;
    flex-direction: column;
`;

export const IconWrapper = styled("div")`
    min-width: 30px;
    display: flex;
    justify-content: flex-end;
`;
export const Subtitle = styled("div")`
    ${({ theme }) => theme.text.paragraph.small}
    color: ${({ theme }) => theme.colors.gray["640"]};
    width: 100%;
    margin-top: 5px;
`;

export const FilterItem = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.text.h6}
    padding: 30px 0;
    border-bottom: 1px solid
        ${({ theme }) => theme.colors.primary.gunmetal["160"]};
`;

interface CocktailsFiltersModalProps {
    isOpen: boolean;
    forBrand: boolean;
    setOpenModal: (isOpen: boolean) => void;
}

const CategoriesModal = (props: CocktailsFiltersModalProps) => {
    const { isOpen, setOpenModal, forBrand } = props;
    const { state } = useArticlesContext();

    const { data: alcoholData } = useGetCategoriesQuery({
        per_page: 50,
    });

    const isTabletSize = useCheckWindowWidth("tablet");
    const [filterBy, setFilterBy] = useState("");

    const availableFilters = [
        {
            id: 1,
            name: "Alkohol",
            category: "alcohol",
            choiceAlcoholCategories: displayNameChoices(
                alcoholData,
                state.categories
            ),
        },
    ];

    const handleCloseModal = () => {
        if (filterBy) {
            setFilterBy("");
        } else setOpenModal(false);
    };

    const handleChangeFilters = () => {
        switch (filterBy) {
            case "alcohol":
                return (
                    <Alcohol
                        forBrand={forBrand}
                        setOpenModal={setOpenModal}
                        choicesCategories={displayChoices(
                            alcoholData,
                            state.categories
                        )}
                    />
                );
        }
    };

    const handleTitleName = () => {
        switch (filterBy) {
            case "alcohol":
                return "alkohol";
            default:
                return "kategorię";
        }
    };

    return (
        <>
            {isOpen && <StyledOverlay onClick={() => setOpenModal(false)} />}
            <StyledModal isOpen={isOpen}>
                <HeaderMain
                    color={!isTabletSize ? "light" : undefined}
                    size="small"
                >
                    <ButtonIcon
                        color={isTabletSize ? "light" : "dark"}
                        onClick={handleCloseModal}
                    >
                        {filterBy ? <IconChevronLeft /> : <ExitIcon />}
                    </ButtonIcon>

                    <HeaderTitle>Wybierz {handleTitleName()}</HeaderTitle>
                </HeaderMain>
                <Wrapper>
                    {!filterBy ? (
                        <FilterContainer>
                            {availableFilters.map((filter) => (
                                <FilterItem
                                    key={filter.id}
                                    onClick={() => setFilterBy(filter.category)}
                                >
                                    <FilterItemBox>
                                        {filter.name}
                                        <Subtitle>
                                            {filter.choiceAlcoholCategories}
                                        </Subtitle>
                                    </FilterItemBox>
                                    <IconWrapper>
                                        <ChevronRight />
                                    </IconWrapper>
                                </FilterItem>
                            ))}
                        </FilterContainer>
                    ) : (
                        handleChangeFilters()
                    )}
                </Wrapper>
            </StyledModal>
        </>
    );
};
export default CategoriesModal;
