import styled from "@emotion/styled";
import { ImStarFull } from "react-icons/im";

const StarsWrapper = styled("div")`
    position: relative;
    display: flex;
    justify-content: flex-end;
`;
const Star = styled(ImStarFull)<{
    stars: number;
}>`
    position: relative;
    color: ${({ theme }) => theme.colors.primary.aliceBlue};
    margin-left: 2px;
    margin-right: 2px;
    font-size: 14px;
    &:first-of-type {
        margin-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }
    &:nth-of-type(1) {
        color: ${({ theme }) => theme.colors.primary.carolinaBlue};
    }
    ${({ theme, stars }) =>
        stars > 1 &&
        `
            &:nth-of-type(2) {
                color: ${theme.colors.primary.carolinaBlue};
            }
        `}
    ${({ theme, stars }) =>
        stars > 2 &&
        `
            &:nth-of-type(3) {
                color: ${theme.colors.primary.carolinaBlue};
            }
        `}
`;

interface StarsProps {
    stars: number;
}

const Stars = (props: StarsProps) => {
    const { stars } = props;

    return (
        <StarsWrapper>
            <Star stars={stars} />
            <Star stars={stars} />
            <Star stars={stars} />
        </StarsWrapper>
    );
};

export default Stars;
