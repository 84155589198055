export const LevelHelper = (name: string) => {
    let stars = 1;
    switch (name) {
        case "Średnie":
            stars = 2;
            break;
        case "Zaawansowane":
            stars = 3;
            break;
        default:
            stars = 1;
    }
    return stars;
};
