import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse } from "../../types/Api/Auth";

export type AuthState =
    | {
          loggedIn: true;
          data: LoginResponse;
      }
    | {
          loggedIn: false;
          data: null;
      };

const initialState = {
    loggedIn: false,
    data: null,
} as AuthState;

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logIn: (state, action: PayloadAction<LoginResponse>) => ({
            loggedIn: true,
            data: action.payload,
        }),
        logOut: (state) => ({
            loggedIn: false,
            data: null,
        }),
    },
});

export const { logIn, logOut } = authSlice.actions;
