import React from "react";
import StyledSvg from "./StyledSvg";

interface IconArticleProps {
    height?: number;
    color?: string;
    display?: string;
}
const IconArticle = (props: IconArticleProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path className="stroke" d="M22.5 4H2.5V20H22.5V4Z" />
                <path className="stroke" d="M6.5 8H9.5" />
                <path className="stroke" d="M11.5 8H18.5" />
                <path className="stroke" d="M6.5 12H9.5" />
                <path className="stroke" d="M11.5 12H18.5" />
                <path className="stroke" d="M6.5 16H9.5" />
                <path className="stroke" d="M11.5 16H18.5" />
            </svg>
        </StyledSvg>
    );
};

const IconArticleFull = (props: IconArticleProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path className="stroke fill" d="M22.5 4H2.5V20H22.5V4Z" />
                <path stroke="#222D38" d="M6.5 8H9.5" />
                <path stroke="#222D38" d="M11.5 8H18.5" />
                <path stroke="#222D38" d="M6.5 12H9.5" />
                <path stroke="#222D38" d="M11.5 12H18.5" />
                <path stroke="#222D38" d="M6.5 16H9.5" />
                <path stroke="#222D38" d="M11.5 16H18.5" />
            </svg>
        </StyledSvg>
    );
};

export { IconArticle, IconArticleFull };
