import React from "react";
import StyledSvg from "./StyledSvg";

interface IconQuizFullProps {
    height?: number;
    color?: string;
    display?: string;
}
const IconQuiz = (props: IconQuizFullProps) => {
    const { height, color, display } = props;
    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className="stroke"
                    d="M7.25 13.9625C8.53265 15.4572 10.4093 16.4 12.5 16.4C14.5907 16.4 16.4673 15.4572 17.75 13.9625M7.25 13.9625C6.16086 12.6932 5.5 11.026 5.5 9.2C5.5 5.22355 8.63401 2 12.5 2C16.366 2 19.5 5.22355 19.5 9.2C19.5 11.026 18.8391 12.6932 17.75 13.9625M7.25 13.9625V22L12.5 20.1L17.75 22V13.9625"
                />
                <path
                    className="fill"
                    d="M12.5 7C11.3954 7 10.5 7.89543 10.5 9C10.5 9.50722 10.6888 9.97035 11 10.3229C11.3665 10.7381 11.9027 11 12.5 11C13.0973 11 13.6335 10.7381 14 10.3229C14.3112 9.97035 14.5 9.50722 14.5 9C14.5 7.89543 13.6046 7 12.5 7Z"
                />
            </svg>
        </StyledSvg>
    );
};
const IconQuizFull = (props: IconQuizFullProps) => {
    const { height, color, display } = props;
    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className="fill stroke"
                    d="M7.25 13.9625C8.53265 15.4572 10.4093 16.4 12.5 16.4C14.5907 16.4 16.4673 15.4572 17.75 13.9625M7.25 13.9625C6.16086 12.6932 5.5 11.026 5.5 9.2C5.5 5.22355 8.63401 2 12.5 2C16.366 2 19.5 5.22355 19.5 9.2C19.5 11.026 18.8391 12.6932 17.75 13.9625M7.25 13.9625V22L12.5 20.1L17.75 22V13.9625"
                />
                <path
                    fill="#222D38"
                    d="M12.5 7C11.3954 7 10.5 7.89543 10.5 9C10.5 9.50722 10.6888 9.97035 11 10.3229C11.3665 10.7381 11.9027 11 12.5 11C13.0973 11 13.6335 10.7381 14 10.3229C14.3112 9.97035 14.5 9.50722 14.5 9C14.5 7.89543 13.6046 7 12.5 7Z"
                />
            </svg>
        </StyledSvg>
    );
};
export { IconQuiz, IconQuizFull };
