import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

export const ListItemLinkWrapper = styled(NavLink)`
    position: relative;
    text-decoration: none;
    display: block;
    margin-bottom: 16px;
    padding-bottom: 16px;
    ${({ theme }) => theme.colors.primary.gunmetal["160"]};
    border-bottom: 1px solid
        ${({ theme }) => theme.colors.primary.gunmetal["160"]};
`;
