import React from "react";
import StyledSvg from "./StyledSvg";

const IconBlueQuiz = () => {
    return (
        <StyledSvg>
            <svg
                width="13"
                height="17"
                viewBox="0 0 13 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2.5625 9.47186C3.52449 10.5929 4.93197 11.3 6.5 11.3C8.06803 11.3 9.47551 10.5929 10.4375 9.47186M2.5625 9.47186C1.74565 8.51993 1.25 7.2695 1.25 5.9C1.25 2.91766 3.60051 0.5 6.5 0.5C9.39949 0.5 11.75 2.91766 11.75 5.9C11.75 7.2695 11.2544 8.51993 10.4375 9.47186M2.5625 9.47186V15.5L6.5 14.075L10.4375 15.5V9.47186"
                    stroke="#2D8EFB"
                    stroke-miterlimit="10"
                />
                <path
                    d="M6.5 4.25C5.67157 4.25 5 4.92157 5 5.75C5 6.13042 5.14161 6.47776 5.375 6.74218C5.64985 7.05359 6.05199 7.25 6.5 7.25C6.94801 7.25 7.35015 7.05359 7.625 6.74218C7.85839 6.47776 8 6.13042 8 5.75C8 4.92157 7.32843 4.25 6.5 4.25Z"
                    fill="#2D8EFB"
                />
            </svg>
        </StyledSvg>
    );
};
export { IconBlueQuiz };
