import styled from "@emotion/styled";

export const HeaderMain = styled("div")<{
    size?: "small" | "large";
    color?: "light";
    inModal?: boolean;
    large?: boolean;
}>`
    height: ${({ size }) => (size === "large" ? "250px" : "auto")};
    background-color: ${({ color, theme }) =>
        color === "light"
            ? "transparent"
            : theme.colors.primary.gunmetal["1000"]};
    color: ${({ color, theme }) =>
        color === "light"
            ? theme.colors.primary.gunmetal["1000"]
            : theme.colors.white["1000"]};
    display: flex;
    flex-direction: ${({ size }) => (size === "small" ? "row" : "column")};
    align-items: ${({ size }) => (size === "small" ? "center" : "start")};
    justify-content: flex-start;
    padding: ${({ inModal }) =>
        inModal ? "16px 16px 0 16px" : "24px 24px 0 24px"};
    ${({ size }) =>
        size === "small" &&
        `
            padding-bottom: 24px;
        `}
`;

export const HeaderTitle = styled("div")`
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    padding-right: 40px;
    ${({ theme }) => theme.text.h6}
`;

export const HeaderBox = styled("div")`
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
`;
