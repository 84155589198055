import { useIonRouter, IonRouterOutlet } from "@ionic/react";
import { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router";
import { useAppSelector } from "../../hooks/redux/useAppSelector";
import { AgeGatePage } from "../../pages/AgeGate";
import { ArticleListPage } from "../../pages/Articles/ArticleListPage";
import { ArticlePage } from "../../pages/Articles/ArticlePage";
import { CocktailListPage } from "../../pages/Cocktails/CocktailListPage";
import { CocktailPage } from "../../pages/Cocktails/CocktailPage";
import { DashboardPage } from "../../pages/Dashboard";
import { LoginPage } from "../../pages/Login";
import { QuizListPage } from "../../pages/Quizzes/QuizListPage";
import { QuizPage } from "../../pages/Quizzes/QuizPage";
import ErrorPage from "../ErrorPage/ErrorPage";
import { TrainingListPage } from "../../pages/Training/TrainingListPage";

export const AppRouting = () => {
    const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);

    const router = useIonRouter();

    useEffect(() => {
        if (!isLoggedIn) router.push("/login", "none", "replace");
    }, [isLoggedIn, router]);

    return (
        <IonRouterOutlet animated={false}>
            <Switch>
                <Route path="/login" component={LoginPage} />
                <Route path="/agegate" component={AgeGatePage} />
                <Route path="/quizzes" component={QuizListPage} />
                <Route path="/quiz/:id" component={QuizPage} />
                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/recipes" component={CocktailListPage} />
                <Route path="/recipe/:id" component={CocktailPage} />
                <Route path="/knowledge-base" component={ArticleListPage} />
                <Route path="/training" component={TrainingListPage} />
                <Route path="/article/:id" component={ArticlePage} />
                <Redirect path="/" to="/dashboard" />
                <Route component={ErrorPage} />
            </Switch>
        </IonRouterOutlet>
    );
};
