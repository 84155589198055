import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import styled from "@emotion/styled";
import { ThemeProvider } from "@emotion/react";
import defaultTheme from "../../theme/defaultTheme";
import MainNav from "../Nav/MainNav";
import { AppRouting } from "./AppRouting";

import "@ionic/react/css/core.css";
import "./App.scss";

setupIonicReact({ mode: "ios" });

const StyledIonApp = styled(IonApp)`
    @media only screen and (min-width: 1000px) {
        min-width: 1000px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
`;

const App = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <StyledIonApp>
                <IonReactRouter>
                    <IonRouterOutlet animated={false}>
                        <AppRouting />
                    </IonRouterOutlet>
                    <MainNav />
                </IonReactRouter>
            </StyledIonApp>
        </ThemeProvider>
    );
};

export default App;
