import { useState } from "react";
import { useGetCocktailsQuery } from "../../../../services/cocktail";
import { ModalButton } from "../../../Controls/Buttons/ModalButton";
import { StyledIonCheckbox } from "../../../Controls/Inputs/CheckboxSelect";
import Input from "../../../Controls/Inputs/Input";
import Loader from "../../../Controls/Loader/Loader";
import { QuizButtonsWrapper } from "../../../Quizzes/Quiz/QuizButtonsWrapper";
import { NotFoundText } from "../CocktailsFiltersModal";
import { Magnifier } from "../../../Icons/Magnifier";
import { useGetIngredientsQuery } from "../../../../services/ingredient";
import { useCocktailsContext } from "../../../../hooks/useAppContext";

interface GlassTypeProps {
    choicesCocktails: number[];
    setOpenModal: (isOpen: boolean) => void;
}

const GlassType = (props: GlassTypeProps) => {
    const { setOpenModal, choicesCocktails } = props;
    const [searchIngredientValue, setSearchIngredientValue] = useState("");
    const [choiceCocktails, setChoiceCocktails] =
        useState<number[]>(choicesCocktails);
    const { dispatch, state } = useCocktailsContext();

    const {
        data: ingredientsData,
        isFetching,
        isSuccess,
    } = useGetIngredientsQuery({
        search: searchIngredientValue,
        per_page: 50,
    });

    const { data: cocktailsData, isFetching: fetchingData } =
        useGetCocktailsQuery({
            search: state.search,
            ingredients_ids:
                choiceCocktails.length > 0 ? choiceCocktails : undefined,
            per_page: 50,
        });

    const handleShowResult = () => {
        dispatch({
            type: "FILTER_BY_INGREDIENTS",
            payload: choiceCocktails,
        });
        setOpenModal(false);
    };

    const handleCheckCategory = (id: number) => {
        setChoiceCocktails((choiceCocktails) => {
            if (choiceCocktails.includes(id)) {
                return choiceCocktails.filter((cocktail) => cocktail !== id);
            } else {
                return [...choiceCocktails, id];
            }
        });
    };
    return (
        <>
            <Input
                icon={<Magnifier height={25} />}
                type="text"
                value={searchIngredientValue}
                placeholder="Znajdź składnik"
                onChange={(value) => setSearchIngredientValue(value)}
                marginBottom={16}
            />
            {isFetching ? (
                <Loader fullScreen />
            ) : isSuccess ? (
                ingredientsData?.data.map((ingredient) => (
                    <StyledIonCheckbox
                        key={ingredient.id}
                        onIonChange={() => handleCheckCategory(ingredient.id)}
                        labelPlacement="end"
                        checked={choiceCocktails.some(
                            (item) => item === ingredient.id
                        )}
                    >
                        {ingredient.name}
                    </StyledIonCheckbox>
                ))
            ) : null}
            {ingredientsData?.data.length === 0 && (
                <NotFoundText>{`Nie znaleziono składnika o nazwie ${searchIngredientValue}`}</NotFoundText>
            )}

            <QuizButtonsWrapper>
                <ModalButton
                    color={"white"}
                    onClick={() => setChoiceCocktails([])}
                >
                    wyczyść
                </ModalButton>
                <ModalButton
                    disabled={fetchingData || !cocktailsData?.meta.total}
                    onClick={handleShowResult}
                >
                    {fetchingData ? (
                        <Loader />
                    ) : (
                        `wyświetl (${cocktailsData?.meta.total ?? "0"})`
                    )}
                </ModalButton>
            </QuizButtonsWrapper>
        </>
    );
};

export default GlassType;
