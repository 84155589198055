import { UseLazyQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { useState, useEffect, useCallback } from "react";
import { ApiResponseMultipleItems } from "../types/Api/ApiResponse";
import { QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { ApiRequestMultipleItemsParams } from "../types/Api/ApiRequestParams";
import useDeepCompareEffect from "use-deep-compare-effect";

export const useInfiniteQuery = <ItemType>(
    useGetDataListQuery: UseLazyQuery<
        QueryDefinition<
            ApiRequestMultipleItemsParams<ItemType>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any,
            string,
            ApiResponseMultipleItems<ItemType>
        >
    >,
    queryParams: Omit<ApiRequestMultipleItemsParams<ItemType>, "page"> &
        Record<string, unknown>
) => {
    const [combinedData, setCombinedData] = useState<ItemType[]>([]);
    const [trigger, queryResponse] = useGetDataListQuery();

    const items = queryResponse.data?.data;
    const currentPage = queryResponse.data?.meta.current_page ?? 1;
    const maxPages = queryResponse.data?.meta.last_page ?? currentPage + 1;
    const hasMore = currentPage < maxPages;

    useDeepCompareEffect(() => {
        trigger(
            {
                ...queryParams,
                page: 1,
            },
            true
        );
    }, [queryParams, trigger]);

    useEffect(() => {
        if (items) {
            if (currentPage === 1) setCombinedData(items);
            else setCombinedData((previousData) => [...previousData, ...items]);
        }
    }, [items, currentPage]);

    const readMore = useCallback(async () => {
        if (hasMore) {
            await trigger(
                {
                    page: currentPage + 1,
                    ...queryParams,
                },
                true
            );
        }
    }, [currentPage, hasMore, queryParams, trigger]);

    return {
        combinedData,
        currentPage,
        readMore,
        hasMore,
        isLoading: queryResponse.isLoading,
        isFetching: queryResponse.isFetching,
    };
};
