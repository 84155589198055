import styled from "@emotion/styled";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { Quiz } from "../../../types/Quiz";
import RoundedProgressBar from "../../Controls/ProgressBar/RoundedProgressBar";
import { ListItemImageWrapper } from "../../Layout/List/ListItem/ListItemImageWrapper";
import { ListItemLinkWrapper } from "../../Layout/List/ListItem/ListItemLinkWrapper";
import { ListItemTitle } from "../../Layout/List/ListItem/ListItemTitle";
import { useTheme } from "@emotion/react";
import { formatDateMonthLong } from "../../../helpers/formathelpers";
import { devices } from "../../../theme/devices";

export const StyledListItemLinkWrapper = styled(ListItemLinkWrapper)`
    width: auto;
    @media only screen and (min-width: ${devices.tablet}) {
        width: 47%;
    }
`;
const IonRowCenter = styled(IonRow)`
    align-items: center;
`;

const ArticleSubheader = styled("p")<{
    isDone?: boolean;
}>`
    color: ${({ theme, isDone }) => theme.colors.gray[isDone ? "640" : "1000"]};
    font-size: 12px;
    margin: 0;
    font-weight: ${({ isDone }) => (isDone ? "500" : "700")};
`;

interface QuizListItemProps {
    item: Quiz;
    isDone?: boolean;
}

export const QuizListItem = (props: QuizListItemProps) => {
    const { item, isDone } = props;
    const theme = useTheme();

    return (
        <StyledListItemLinkWrapper to={`/quiz/${item.id}`}>
            <IonGrid>
                <IonRowCenter>
                    <IonCol size="auto">
                        <ListItemImageWrapper backgroundUrl={item.photo_path} />
                    </IonCol>
                    <IonCol>
                        <ListItemTitle>{item.name}</ListItemTitle>
                        {isDone ? (
                            <ArticleSubheader isDone={isDone}>
                                {formatDateMonthLong(item.data)}
                            </ArticleSubheader>
                        ) : (
                            <ArticleSubheader>Rozpocznij quiz</ArticleSubheader>
                        )}
                    </IonCol>
                    <IonCol size="auto">
                        {isDone && (
                            <RoundedProgressBar
                                value={item.score / 100}
                                size="small"
                                backgroundColor={theme.colors.white["1000"]}
                                foregroundColor={
                                    theme.colors.primary.carolinaBlue
                                }
                                textColor={
                                    theme.colors.primary.gunmetal["1000"]
                                }
                            />
                        )}
                    </IonCol>
                </IonRowCenter>
            </IonGrid>
        </StyledListItemLinkWrapper>
    );
};
