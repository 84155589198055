import React from "react";
import styled from "@emotion/styled";
import { IonContent, IonPage } from "@ionic/react";
import { HeaderMain } from "../components/Layout/Header/HeaderMainStyles";
import TitleHeadline from "../components/Layout/Header/TitleHeadline";
import Logo from "../components/Layout/Header/Logo";

import LoginNav from "../components/Nav/LoginNav";
import Button from "../components/Controls/Buttons/Button";

import bottomBackground from "./../assets/img/loginBackground.jpg";
import topBackground from "./../assets/img/logoShape.svg";

const StyledIonContent = styled(IonContent)`
    --background: transparent;
    background-image: linear-gradient(
            to top,
            rgba(255, 255, 255, 100) 100px,
            rgba(255, 255, 255, 0) 140px
        ),
        url(${topBackground}), url(${bottomBackground});
    background-size: 100%, contain, cover;
    background-position: center, bottom -10% center, center;
    background-repeat: no-repeat;
    background-color: white;
`;

export const AgeGatePage = () => {
    return (
        <IonPage>
            <StyledIonContent fullscreen>
                <HeaderMain color="light">
                    <Logo type="light" />
                </HeaderMain>
                <TitleHeadline
                    headline="Bramka Wiekowa"
                    text="Dolore exercitation nulla tempor consequat aliquip occaecat. Nisi id ipsum irure aute."
                    type="light"
                ></TitleHeadline>
                <LoginNav>
                    <Button>Kontynnuj</Button>
                </LoginNav>
            </StyledIonContent>
        </IonPage>
    );
};
