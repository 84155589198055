import React from "react";
import StyledSvg from "./StyledSvg";

const IconPdf = () => {
    return (
        <StyledSvg>
            <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.5418 4.09859V13.5H1.4585V0.5H7.87898L11.5418 4.09859Z"
                    stroke="#2D8EFB"
                />
                <path
                    d="M3.18604 8.68494C3.18604 8.60327 3.22315 8.56244 3.29739 8.56244H4.45633C4.6048 8.56244 4.73128 8.58723 4.83576 8.63681C4.94024 8.6864 5.02548 8.75202 5.09147 8.83369C5.15746 8.91536 5.2042 9.00869 5.2317 9.11369C5.26194 9.21869 5.27706 9.32661 5.27706 9.43744C5.27706 9.54827 5.26194 9.65619 5.2317 9.76119C5.2042 9.86619 5.15746 9.95952 5.09147 10.0412C5.02548 10.1229 4.94024 10.1885 4.83576 10.2381C4.73128 10.2876 4.6048 10.3124 4.45633 10.3124H3.6727V11.5024C3.6727 11.5841 3.63559 11.6249 3.56135 11.6249H3.29739C3.22315 11.6249 3.18604 11.5841 3.18604 11.5024V8.68494ZM4.76977 9.43306C4.76977 9.29306 4.73953 9.18369 4.67904 9.10494C4.61855 9.02619 4.51132 8.98681 4.35734 8.98681H3.6727V9.88806H4.35734C4.51132 9.88806 4.61855 9.84869 4.67904 9.76994C4.73953 9.68827 4.76977 9.57598 4.76977 9.43306Z"
                    fill="#2D8EFB"
                />
                <path
                    d="M5.66707 8.68494C5.66707 8.60327 5.70419 8.56244 5.77843 8.56244H6.62804C6.8425 8.56244 7.0226 8.58869 7.16832 8.64119C7.3168 8.69369 7.4364 8.77973 7.52714 8.89931C7.61787 9.0189 7.68386 9.1764 7.7251 9.37181C7.76635 9.56723 7.78697 9.80786 7.78697 10.0937C7.78697 10.3795 7.76635 10.6201 7.7251 10.8156C7.68386 11.011 7.61787 11.1685 7.52714 11.2881C7.4364 11.4076 7.3168 11.4937 7.16832 11.5462C7.0226 11.5987 6.8425 11.6249 6.62804 11.6249H5.77843C5.70419 11.6249 5.66707 11.5841 5.66707 11.5024V8.68494ZM6.62804 11.2006C6.75726 11.2006 6.86312 11.1831 6.94561 11.1481C7.02809 11.1101 7.09271 11.0489 7.13945 10.9643C7.18619 10.8768 7.21781 10.7631 7.23431 10.6231C7.25356 10.4801 7.26318 10.3037 7.26318 10.0937C7.26318 9.88369 7.25356 9.70869 7.23431 9.56869C7.21781 9.42577 7.18619 9.31202 7.13945 9.22744C7.09271 9.13994 7.02809 9.07869 6.94561 9.04369C6.86312 9.00577 6.75726 8.98681 6.62804 8.98681H6.15374V11.2006H6.62804Z"
                    fill="#2D8EFB"
                />
                <path
                    d="M8.24074 8.68494C8.24074 8.60327 8.27786 8.56244 8.3521 8.56244H9.75024C9.82723 8.56244 9.86572 8.60036 9.86572 8.67619V8.87306C9.86572 8.9489 9.82723 8.98681 9.75024 8.98681H8.72741V9.85744H9.67188C9.74887 9.85744 9.78736 9.89681 9.78736 9.97556V10.1681C9.78736 10.2439 9.74887 10.2818 9.67188 10.2818H8.72741V11.5024C8.72741 11.5841 8.69029 11.6249 8.61605 11.6249H8.3521C8.27786 11.6249 8.24074 11.5841 8.24074 11.5024V8.68494Z"
                    fill="#2D8EFB"
                />
                <path d="M7.88574 0.194519V4.08341H11.7451" stroke="#2D8EFB" />
            </svg>
        </StyledSvg>
    );
};
export { IconPdf };
