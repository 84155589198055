import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import styled from "@emotion/styled";
import { FreeMode } from "swiper";
import { ImageCard, ImageCardProps } from "../ImageCard/ImageCard";
import { useWindowWidth } from "../../../hooks/redux/useWindowWidth";

const StyledSwiper = styled(Swiper)`
    width: 100%;
    height: auto;
`;

const StyledSwiperSlide = styled(SwiperSlide)``;

interface CardSwiperProps {
    cardVariant: "round" | "square";
    items: (Omit<ImageCardProps, "variant"> & {
        onClick: () => void;
        id: number;
    })[];
}

export const CardSwiper = (props: CardSwiperProps) => {
    const { cardVariant, items } = props;
    const windowWidth = useWindowWidth();
    const slidesPerView = (windowWidth - 20) / 228; // width card + margin
    const spaceBetween = 20; // margin

    return (
        <StyledSwiper
            slidesPerView={windowWidth < 999 ? slidesPerView : 4.29}
            spaceBetween={spaceBetween}
            modules={[FreeMode]}
            freeMode={true}
            className="mySwiper"
        >
            {items.map((item) => (
                <StyledSwiperSlide key={item.id} onClick={item.onClick}>
                    <ImageCard variant={cardVariant} {...item} />
                </StyledSwiperSlide>
            ))}
        </StyledSwiper>
    );
};
