import { IonContent, IonPage } from "@ionic/react";
import DashboardContent from "../components/Dashboard/DashboardContent";
import HeaderMainComplex from "../components/Layout/Header/HeaderMainComplex";

export const DashboardPage = () => {
    return (
        <IonPage>
            <IonContent fullscreen>
                <HeaderMainComplex
                    size="large"
                    headline="Cześć"
                    text="Dobrze Cię ponownie widzieć."
                />
                <DashboardContent />
            </IonContent>
        </IonPage>
    );
};
