import React from "react";
import styled from "@emotion/styled";
import { LogoSvg } from "../../Icons/Logo";

const LogoStyle = styled("div")<{
    type?: "light" | "dark";
}>`
    margin-right: 8px;
    path {
        fill: ${({ type, theme }) => {
            switch (type) {
                case "light":
                    return `
                    ${theme.colors.white["1000"]};
                 `;
                case "dark":
                    return `
                ${theme.colors.primary.gunmetal["1000"]};
                 `;
                default:
                    return theme.colors.white["1000"];
            }
        }}
`;

interface LogoProps {
    type?: "light" | "dark";
}

const Logo = (props: LogoProps) => {
    const { type } = props;

    return (
        <LogoStyle type={type}>
            <LogoSvg />
        </LogoStyle>
    );
};

export default Logo;
