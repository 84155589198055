import "@emotion/react";
import themeColors from "./themeColors";
import themeText from "./themeText";
import themeForm from "./themeForm";

const defaultTheme = {
    colors: themeColors,
    text: themeText,
    form: themeForm,
};

export default defaultTheme;