import React from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation } from "react-router-dom";
import MainNavItem from "./MainNavItem";
import {
    IconArticle,
    IconArticleFull,
    IconGlass,
    IconGlassFull,
    IconHome,
    IconHomeFull,
    IconQuiz,
    IconQuizFull,
} from "../Icons/FullIconsList";
import { devices } from "../../theme/devices";
import { isPathActive } from "../../helpers/isPathActive";
import { IconTraining, IconTrainingFull } from "../Icons/TrainingIcon";

const BottomBarWrapper = styled("div")`
    background-color: ${({ theme }) => theme.colors.primary.gunmetal["1000"]};
    color: ${({ theme }) => theme.colors.white["1000"]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 16px 24px 16px;
    z-index: 99;

    @media only screen and (min-width: ${devices.tablet}) {
        display: none;
    }
`;

const StyledLink = styled(NavLink)`
    height: 64px;
    min-width: 64px;
    color: ${({ theme }) => theme.colors.white["1000"]};
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    ${({ theme }) => theme.text.paragraph.tiny}
`;

const MainNav = () => {
    const location = useLocation();

    const hideNavigation =
        isPathActive("/article", location) ||
        isPathActive("/quiz", location) ||
        isPathActive("/recipe", location);

    if (hideNavigation) return null;

    return (
        <BottomBarWrapper>
            <StyledLink to="/dashboard">
                <MainNavItem
                    name="Home"
                    ActiveIcon={IconHomeFull}
                    InactiveIcon={IconHome}
                    active={isPathActive("/dashboard", location)}
                />
            </StyledLink>
            <StyledLink to="/knowledge-base">
                <MainNavItem
                    name="Baza wiedzy"
                    ActiveIcon={IconArticleFull}
                    InactiveIcon={IconArticle}
                    active={isPathActive("/knowledge-base", location)}
                />
            </StyledLink>
            <StyledLink to="/training">
                <MainNavItem
                    name="Szkolenia"
                    ActiveIcon={IconTrainingFull}
                    InactiveIcon={IconTraining}
                    active={isPathActive("/training", location)}
                />
            </StyledLink>
            <StyledLink to="/quizzes/undone">
                <MainNavItem
                    name="Quizy"
                    ActiveIcon={IconQuizFull}
                    InactiveIcon={IconQuiz}
                    active={isPathActive("/quizzes", location)}
                />
            </StyledLink>
            <StyledLink to="/recipes">
                <MainNavItem
                    name="Koktajle"
                    ActiveIcon={IconGlassFull}
                    InactiveIcon={IconGlass}
                    active={isPathActive("/recipes", location)}
                />
            </StyledLink>
        </BottomBarWrapper>
    );
};

export default MainNav;
