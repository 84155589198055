export const ssoConfig = {
    loginUrl:
        "https://login.microsoftonline.com/" +
        process.env.REACT_APP_TENAT_ID +
        "/oauth2/v2.0/authorize",
    logoutUrl:
        "https://login.microsoftonline.com/" +
        process.env.REACT_APP_TENAT_ID +
        "/oauth2/v2.0/logout",
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    scope: "User.Read",
    responseType: "code",
    responseMode: "query",
    redirectUri: new URL(
        "/login",
        process.env.REACT_APP_PUBLIC_URL ?? "http://localhost:3000"
    ).toString(),
};
