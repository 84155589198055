import React from "react";
import StyledSvg from "./StyledSvg";

interface IconTrainingProps {
    height?: number;
    color?: string;
    display?: string;
}
const IconTraining = (props: IconTrainingProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.75 21H9.75M18.75 21H15.9137M9.75 21L12.75 18M9.75 21H15.9137M12.75 18H3.75V4H21.75V18H12.75ZM12.75 18L15.9137 21"
                    stroke="white"
                    stroke-width="1.6"
                    stroke-linejoin="round"
                />
            </svg>
        </StyledSvg>
    );
};

const IconTrainingFull = (props: IconTrainingProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="3.75" y="4" width="18" height="14" fill="white" />
                <path
                    d="M6.75 21H9.75M18.75 21H15.9137M9.75 21L12.75 18M9.75 21H15.9137M12.75 18H3.75V4H21.75V18H12.75ZM12.75 18L15.9137 21"
                    stroke="white"
                    stroke-width="1.6"
                    stroke-linejoin="round"
                />
            </svg>
        </StyledSvg>
    );
};
export { IconTraining, IconTrainingFull };
