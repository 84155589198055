import { ApiRequestMultipleItemsParams } from "../types/Api/ApiRequestParams";
import { ApiResponseMultipleItems } from "../types/Api/ApiResponse";
import { CocktailGlassTypes } from "../types/Cocktail";
import { api } from "./api";

export const glassTypesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getGlassTypes: build.query<
            ApiResponseMultipleItems<CocktailGlassTypes>,
            ApiRequestMultipleItemsParams<CocktailGlassTypes>
        >({
            query: (params) => ({ url: `/glass-types`, params }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetGlassTypesQuery, useLazyGetGlassTypesQuery } =
    glassTypesApi;
