import { ApiRequestMultipleItemsParams } from "../types/Api/ApiRequestParams";
import { ApiResponseMultipleItems } from "../types/Api/ApiResponse";
import { CocktailTaste } from "../types/Cocktail";
import { api } from "./api";

export const tasteApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTastes: build.query<
            ApiResponseMultipleItems<CocktailTaste>,
            ApiRequestMultipleItemsParams<CocktailTaste>
        >({
            query: (params) => ({ url: `/tastes`, params }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetTastesQuery, useLazyGetTastesQuery } = tasteApi;
