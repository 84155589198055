type Data = {
    id: number;
    name: string;
};
interface FilterData {
    data: Data[];
}

export const displayNameChoices = (
    filterData?: FilterData,
    choicesCategories?: number[]
) => {
    const choiceNames = filterData?.data
        .filter((item) => choicesCategories?.includes(item.id))
        .map((item) => item.name);
    return choiceNames?.join(", ");
};

export const displayChoices = (
    filterData?: FilterData,
    choicesCategories?: number[]
) => {
    return (
        filterData?.data
            .filter((item) => choicesCategories?.includes(item.id))
            .map((item) => item.id) ?? []
    );
};

export const difficultyLevel = {
    data: [
        {
            id: 1,
            name: "Prosty",
        },
        {
            id: 2,
            name: "Średni",
        },
        {
            id: 3,
            name: "Zaawansowany",
        },
    ],
};
