import { LoginRequest, LoginResponse } from "../types/Api/Auth";
import { api } from "./api";

export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<LoginResponse, LoginRequest>({
            query: (params) => ({
                url: `/login/microsoft/callback`,
                params,
                method: "GET",
            }),
            invalidatesTags: [],
        }),
    }),
    overrideExisting: false,
});

export const { useLoginMutation } = authApi;
