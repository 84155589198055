import { ApiRequestMultipleItemsParams } from "../types/Api/ApiRequestParams";
import { ApiResponseMultipleItems } from "../types/Api/ApiResponse";
import { Category } from "../types/Category";
import { api } from "./api";

export const categoryApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCategories: build.query<
            ApiResponseMultipleItems<Category>,
            ApiRequestMultipleItemsParams<Category>
        >({
            query: (params) => ({ url: `/categories`, params }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetCategoriesQuery, useLazyGetCategoriesQuery } = categoryApi;
