import { useState } from "react";
import { ModalButton } from "../../../Controls/Buttons/ModalButton";
import { StyledIonCheckbox } from "../../../Controls/Inputs/CheckboxSelect";
import Input from "../../../Controls/Inputs/Input";
import Loader from "../../../Controls/Loader/Loader";
import { QuizButtonsWrapper } from "../../../Quizzes/Quiz/QuizButtonsWrapper";
import { Magnifier } from "../../../Icons/Magnifier";
import { NotFoundText } from "../ArticlesFiltersModal";
import { useGetArticlesQuery } from "../../../../services/article";
import { useGetCategoriesQuery } from "../../../../services/category";
import { useArticlesContext } from "../../../../hooks/useAppContext";

interface GlassTypeProps {
    choicesCategories: number[];
    setOpenModal: (isOpen: boolean) => void;
    forBrand: boolean;
}

const GlassType = (props: GlassTypeProps) => {
    const { setOpenModal, choicesCategories, forBrand } = props;
    const [setSearchAlcoholValue, setSetSearchAlcoholValue] = useState("");
    const [choiceArticles, setChoiceArticles] =
        useState<number[]>(choicesCategories);
    const { dispatch, state } = useArticlesContext();

    const {
        data: alcoholData,
        isFetching,
        isSuccess,
    } = useGetCategoriesQuery({
        search: setSearchAlcoholValue,
        per_page: 50,
    });

    const { data: articlesData, isFetching: fetchingData } =
        useGetArticlesQuery({
            search: state.search,
            for_brand: forBrand,
            category_ids:
                choiceArticles.length > 0 ? choiceArticles : undefined,
            per_page: 50,
        });

    const handleShowResult = () => {
        dispatch({
            type: "FILTER_BY_CATEGORIES",
            payload: choiceArticles,
        });
        setOpenModal(false);
    };

    const handleCheckCategory = (id: number) => {
        setChoiceArticles((choiceArticles) => {
            if (choiceArticles.includes(id)) {
                return choiceArticles.filter((article) => article !== id);
            } else {
                return [...choiceArticles, id];
            }
        });
    };

    return (
        <>
            <Input
                icon={<Magnifier height={25} />}
                type="text"
                value={setSearchAlcoholValue}
                placeholder="Znajdź typ alkoholu"
                onChange={(value) => setSetSearchAlcoholValue(value)}
                marginBottom={16}
            />
            {isFetching ? (
                <Loader fullScreen />
            ) : isSuccess ? (
                alcoholData?.data.map((alcohol) => (
                    <StyledIonCheckbox
                        key={alcohol.id}
                        onIonChange={() => handleCheckCategory(alcohol.id)}
                        labelPlacement="end"
                        checked={choiceArticles.some(
                            (item) => item === alcohol.id
                        )}
                    >
                        {alcohol.name}
                    </StyledIonCheckbox>
                ))
            ) : null}
            {alcoholData?.data.length === 0 && (
                <NotFoundText>{`Nie znaleziono alkoholu o nazwie ${setSearchAlcoholValue}`}</NotFoundText>
            )}

            <QuizButtonsWrapper>
                <ModalButton
                    color={"white"}
                    onClick={() => setChoiceArticles([])}
                >
                    wyczyść
                </ModalButton>
                <ModalButton
                    disabled={fetchingData || !articlesData?.meta.total}
                    onClick={handleShowResult}
                >
                    {fetchingData ? (
                        <Loader />
                    ) : (
                        `wyświetl (${articlesData?.meta.total ?? "0"})`
                    )}
                </ModalButton>
            </QuizButtonsWrapper>
        </>
    );
};

export default GlassType;
