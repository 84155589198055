import { ApiRequestMultipleItemsParams } from "../types/Api/ApiRequestParams";
import { ApiResponseMultipleItems } from "../types/Api/ApiResponse";
import { Training } from "../types/Training";
import { api } from "./api";

export const trainingApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTraining: build.query<
            ApiResponseMultipleItems<Training>,
            ApiRequestMultipleItemsParams<Training>
        >({
            query: (params) => ({ url: `/trainings`, params }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetTrainingQuery, useLazyGetTrainingQuery } = trainingApi;
