import React from "react";
import StyledSvg from "./StyledSvg";

interface IconFilterProps {
    height?: number;
    color?: string;
    display?: string;
}
const IconFilter = (props: IconFilterProps) => {
    const { height, color, display } = props;

    return (
        <StyledSvg height={height} color={color} display={display}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M22 6.5H16"
                    className="stroke"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                />
                <path
                    d="M6 6.5H2"
                    className="stroke"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"
                    className="stroke"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                />
                <path
                    d="M22 17.5H18"
                    className="stroke"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 17.5H2"
                    className="stroke"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                />
                <path
                    d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"
                    className="stroke"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                />
            </svg>
        </StyledSvg>
    );
};
export { IconFilter };
