import { IonContent } from "@ionic/react";
import { useParams } from "react-router-dom";
import {
    HeaderMain,
    HeaderTitle,
} from "../../components/Layout/Header/HeaderMainStyles";
import Loader from "../../components/Controls/Loader/Loader";
import { CocktailContent } from "../../components/Cocktails/Cocktail/CocktailContent";
import { useGetCocktailQuery } from "../../services/cocktail";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { StyledIonPage } from "../Articles/ArticlePage";
import { GoBackButton } from "../../components/Controls/Buttons/GoBackButton";

export const CocktailPage = () => {
    const params = useParams<{ id: string }>();
    const cocktailId = Number(params.id) ?? null;

    const {
        currentData: cocktailData,
        isFetching,
        isSuccess,
    } = useGetCocktailQuery(cocktailId ?? skipToken);

    return (
        <StyledIonPage>
            <IonContent fullscreen>
                <HeaderMain size="small">
                    <GoBackButton />
                    {isSuccess && !isFetching && (
                        <HeaderTitle>{cocktailData.data.name}</HeaderTitle>
                    )}
                </HeaderMain>
                {isFetching ? (
                    <Loader fullScreen />
                ) : isSuccess ? (
                    <CocktailContent cocktail={cocktailData.data} />
                ) : null}
            </IonContent>
        </StyledIonPage>
    );
};
