import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Quiz, QuizQuestion, QuizAnswer } from "../../types/Quiz";

export type QuizState = (
    | {
          state: "active" | "finished" | "sent";
          quiz: Quiz;
          answers: QuizAnswer[];
          startTime: string;
      }
    | {
          state: "inactive";
          quiz: null;
          answers: null;
          startTime: null;
      }
) &
    (
        | {
              state: "active" | "finished";
              currentQuestionIndex: number;
              currentQuestion: QuizQuestion;
          }
        | {
              state: "inactive" | "sent";
              currentQuestionIndex: null;
              currentQuestion: null;
          }
    );

const initialState: QuizState = {
    state: "inactive",
    quiz: null,
    startTime: null,
    answers: null,
    currentQuestion: null,
    currentQuestionIndex: null,
} as QuizState;

export const quizSlice = createSlice({
    name: "quiz",
    initialState,
    reducers: {
        previousQuestion: (state) => {
            if (state.state !== "active") {
                console.error(
                    "Error: Attempt to access previous question of an inactive quiz"
                );
                return;
            }
            if (state.currentQuestionIndex === 0) {
                console.error(
                    "Error: Attempt to access inexistent previous question"
                );
                return;
            }

            state.currentQuestionIndex -= 1;
            state.currentQuestion =
                state.quiz.questions[state.currentQuestionIndex];
        },

        nextQuestion: (state) => {
            if (state.state !== "active") {
                console.error(
                    "Error: Attempt to access next question of an inactive quiz"
                );
                return;
            }

            if (
                state.currentQuestionIndex ===
                state.quiz.questions.length - 1
            ) {
                return {
                    ...state,
                    state: "finished",
                };
            } else {
                state.currentQuestionIndex += 1;
                state.currentQuestion =
                    state.quiz.questions[state.currentQuestionIndex];
            }
        },

        answerCurrentQuestion: (state, action: PayloadAction<QuizAnswer>) => {
            if (state.state !== "active") {
                console.error("Error: Attempt to answer an inactive quiz");
                return;
            }

            if (state.currentQuestion.id !== action.payload.question_id) {
                console.error("Error: Attempt to answer a different quiz");
                return;
            }

            state.answers.push(action.payload);
        },

        startQuiz: (_, action: PayloadAction<Quiz>) =>
            action.payload.questions.length > 0
                ? {
                      state: "active",
                      quiz: action.payload,
                      startTime: new Date().toISOString(),
                      answers: [],
                      currentQuestionIndex: 0,
                      currentQuestion: action.payload.questions[0],
                  }
                : initialState,

        quizSent: (state) => {
            if (state.state !== "finished") return;
            return {
                ...state,
                state: "sent",
                currentQuestion: null,
                currentQuestionIndex: null,
            };
        },

        resetQuiz: (_) => initialState,
    },
});

export const {
    answerCurrentQuestion,
    nextQuestion,
    previousQuestion,
    startQuiz,
    quizSent,
    resetQuiz,
} = quizSlice.actions;
