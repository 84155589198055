import { useContext } from "react";
import { ArticlesContext } from "../context/ArticleFiltersContext";
import { CocktailsContext } from "../context/CocktailsFilterContext";

export const useArticlesContext = () => {
    const context = useContext(ArticlesContext);
    if (!context) {
        throw new Error("useAppContext must be used within an AppProvider");
    }
    return context;
};

export const useCocktailsContext = () => {
    const context = useContext(CocktailsContext);
    if (!context) {
        throw new Error("useAppContext must be used within an AppProvider");
    }
    return context;
};
