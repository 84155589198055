import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    createApi,
    fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import queryString from "query-string";
import { RootState } from "../store/store";
import { logOut } from "../store/slices/auth";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    prepareHeaders: async (headers, { getState }) => {
        const token = (getState() as RootState).auth.data?.token;
        if (token) headers.set("Authorization", `Bearer ${token}`);
        return headers;
    },
    paramsSerializer: (params) =>
        queryString.stringify(params, {
            arrayFormat: "index",
        }),
});

export const baseQueryWithAuthHandler: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions = {}) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 401) {
        api.dispatch(logOut());
    }
    return result;
};

export const api = createApi({
    tagTypes: ["MAIN_QUIZ_SCORE", "QUIZ_SCORE", "QUIZ_LIST"],
    baseQuery: baseQueryWithAuthHandler,
    endpoints: () => ({}),
});
