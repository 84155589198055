export const LogoSvg = () => {
    return (
        <svg
            width="46"
            height="45"
            viewBox="0 0 46 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.09807 21.194C11.7089 22.5721 14.1012 24.8622 14.1012 24.8622C14.1284 23.7503 14.417 22.6076 14.8335 21.64C14.8335 21.64 13.6776 19.4824 10.2667 17.1449C6.60381 14.6336 2.42271 13.9333 2.42271 13.9333C1.56969 15.7846 0.963937 17.7719 0.649231 19.8538C0.649231 19.8538 3.70875 19.5202 8.09807 21.194Z"
                fill="FFFFFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6913 13.9033C14.1365 17.2343 15.3812 20.582 15.3812 20.582C16.0188 19.5599 16.8659 18.6798 17.8657 17.9985C17.8657 17.9985 16.5867 13.9825 14.5151 11.0158C12.4435 8.05022 9.08702 5.5874 9.08702 5.5874C7.44842 6.85075 5.98965 8.33412 4.75922 9.99374C4.75922 9.99374 8.17603 11.47 10.6913 13.9033Z"
                fill="FFFFFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.3313 21.194C33.7193 22.5721 31.3283 24.8622 31.3283 24.8622C31.301 23.7503 31.0124 22.6076 30.5959 21.64C30.5959 21.64 31.7518 19.4824 35.1615 17.1449C38.8256 14.6336 43.0067 13.9333 43.0067 13.9333C43.8609 15.7846 44.4643 17.7719 44.779 19.8538C44.779 19.8538 41.7207 19.5202 37.3313 21.194Z"
                fill="FFFFFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.7376 13.9033C31.2936 17.2343 30.0478 20.582 30.0478 20.582C29.4112 19.5599 28.563 18.6798 27.5632 17.9985C27.5632 17.9985 28.8422 13.9825 30.915 11.0158C32.9866 8.05022 36.3419 5.5874 36.3419 5.5874C37.9805 6.85075 39.4393 8.33412 40.6709 9.99374C40.6709 9.99374 37.2529 11.47 34.7376 13.9033Z"
                fill="FFFFFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7518 9.74345C18.3076 13.7216 18.704 17.4939 18.704 17.4939C19.7262 16.9545 21.0536 16.5381 22.2675 16.4387C22.2675 16.4387 22.329 12.6002 21.4843 8.65517C20.4952 4.0442 19.3665 1.18274 19.3665 1.18274C17.3257 1.49621 15.3759 2.08412 13.5563 2.90624C13.5563 2.90624 15.6764 6.99555 16.7518 9.74345Z"
                fill="FFFFFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.6777 9.74345C27.1208 13.7216 26.7256 17.4939 26.7256 17.4939C25.7034 16.9545 24.376 16.5381 23.1609 16.4387C23.1609 16.4387 23.0994 12.6002 23.9453 8.65517C24.9344 4.0442 26.0619 1.18274 26.0619 1.18274C28.1039 1.49621 30.0537 2.08412 31.8733 2.90624C31.8733 2.90624 29.752 6.99555 28.6777 9.74345Z"
                fill="FFFFFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0934 34.7381C15.3964 32.6893 19.736 33.1128 19.736 33.1128C18.1956 32.5403 16.8634 31.549 15.8815 30.2809C15.8815 30.2809 12.0281 29.8208 9.07506 30.4158C4.60528 31.3172 2.62476 32.8691 2.62476 32.8691C3.5322 34.7358 4.69519 36.4557 6.0676 37.9876C6.0676 37.9876 7.91324 36.2523 11.0934 34.7381Z"
                fill="FFFFFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.74859 28.2523C12.1355 28.0417 15.1619 29.188 15.1619 29.188C14.6177 28.2073 14.2616 27.1108 14.1421 25.9456C14.1421 25.9456 10.6578 23.9607 7.44571 23.5301C2.9996 22.9339 0.40741 23.7868 0.40741 23.7868C0.464199 25.8924 0.814399 27.9258 1.41897 29.8468C1.41897 29.8468 4.26789 28.4191 7.74859 28.2523Z"
                fill="FFFFFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.7141 38.0968C28.9941 38.0968 31.7412 43.5311 31.7412 43.5311C33.7005 42.6617 35.5094 41.5225 37.1208 40.1622C37.1208 40.1622 31.8158 33.4894 22.7141 33.4894C13.6137 33.4894 8.30743 40.1622 8.30743 40.1622C9.92119 41.5225 11.7302 42.6617 13.6882 43.5311C13.6882 43.5311 16.4342 38.0968 22.7141 38.0968Z"
                fill="FFFFFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.3356 34.7381C30.0326 32.6893 25.6918 33.1128 25.6918 33.1128C27.2334 32.5403 28.5655 31.549 29.5475 30.2809C29.5475 30.2809 33.4009 29.8208 36.3539 30.4158C40.8237 31.3172 42.8054 32.8691 42.8054 32.8691C41.8968 34.7358 40.7338 36.4557 39.3602 37.9876C39.3602 37.9876 37.5157 36.2523 34.3356 34.7381Z"
                fill="FFFFFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.6803 28.2523C33.2922 28.0417 30.267 29.188 30.267 29.188C30.8112 28.2073 31.1661 27.1108 31.2868 25.9456C31.2868 25.9456 34.7711 23.9607 37.9832 23.5301C42.4293 22.9339 45.0215 23.7868 45.0215 23.7868C44.9647 25.8924 44.6145 27.9258 44.0099 29.8468C44.0099 29.8468 41.1598 28.4191 37.6803 28.2523Z"
                fill="FFFFFF"
            />
        </svg>
    );
};
