import React from "react";
import styled from "@emotion/styled";

const ButtonStyle = styled("button")`
    ${({ theme }) => theme.text.paragraph.regular}
    font-weight: 700;
    height: 100%;
    border: 0;
    borde-radius: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.gray["500"]};
    color: ${({ theme }) => theme.colors.white["1000"]};
    padding-left: 16px;
    padding-right: 16px;
`;

interface ButtonProps {
    type?: string[];
    children?: React.ReactNode;
    onClick?: React.ReactEventHandler;
}

const Button = (props: ButtonProps) => {
    const { children, onClick } = props;

    return (
        <ButtonStyle onClick={onClick ? onClick : undefined}>
            {children}
        </ButtonStyle>
    );
};

export default Button;
