import styled from "@emotion/styled";
import { IconLogout } from "../../Icons/IconLogout";
import HeaderNav from "../../Nav/HeaderNav";
import { HeaderBox, HeaderMain } from "./HeaderMainStyles";
import Logo from "./Logo";
import TitleHeadline from "./TitleHeadline";
import { IonModal } from "@ionic/react";
import { useRef } from "react";
import { ModalButton } from "../../Controls/Buttons/ModalButton";
import { useAppDispatch } from "../../../hooks/redux/useAppDispatch";
import { logOut } from "../../../store/slices/auth";
import { ssoConfig } from "../../../authConfig";

interface HeaderMainProps {
    headline: string;
    text?: string;
    size?: "small" | "large";
}

const LogoutButtonWrapper = styled("button")`
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.white["240"]};
    color: ${({ theme }) => theme.colors.white["1000"]};
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
const StyledIonModal = styled(IonModal)`
    padding: 16px;
    justify-content: center;
    --max-width: 330px;
    --max-height: 200px;
`;
const ButtonBox = styled("div")`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    ion-button {
        width: 100%;
    }
`;
const ModalBox = styled("div")`
    padding: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

const LogoutHeader = styled("h2")`
    margin: 0 0px 16px 0;
`;

const Box = styled("div")`
    display: flex;
    align-items: center;
`;

const HeaderMainComplex = (props: HeaderMainProps) => {
    const { headline, text, size } = props;
    const dispatch = useAppDispatch();

    const handleLogout = async () => {
        dispatch(logOut());
        const url = new URL(ssoConfig.logoutUrl);

        url.searchParams.append("client_id", ssoConfig.clientId);
        url.searchParams.append(
            "post_logout_redirect_uri",
            ssoConfig.redirectUri
        );

        window.open(url.toString());
    };

    const modal = useRef<HTMLIonModalElement>(null);

    const closeModal = () => {
        modal.current?.dismiss();
    };

    return (
        <HeaderMain size={size}>
            <HeaderBox>
                <Box>
                    <Logo />
                    <HeaderNav />
                </Box>
                <LogoutButtonWrapper id="open-dialog">
                    <IconLogout />
                </LogoutButtonWrapper>
            </HeaderBox>
            <TitleHeadline headline={headline} text={text} />

            <StyledIonModal trigger={"open-dialog"} ref={modal} animated={true}>
                <ModalBox>
                    <LogoutHeader>
                        Czy na pewno chcesz się wylogować?
                    </LogoutHeader>
                    <ButtonBox>
                        <ModalButton color={"white"} onClick={closeModal}>
                            Anuluj
                        </ModalButton>
                        <ModalButton onClick={handleLogout}>
                            Wyloguj
                        </ModalButton>
                    </ButtonBox>
                </ModalBox>
            </StyledIonModal>
        </HeaderMain>
    );
};

export default HeaderMainComplex;
