import styled from "@emotion/styled";
import { IonModal, IonText, useIonRouter } from "@ionic/react";
import { Icon404 } from "../Icons/Icon404";
import { ModalButton } from "../Controls/Buttons/ModalButton";

const Wrapper = styled("div")`
    background: ${({ theme }) => theme.colors.primary.gunmetal["1000"]};
    color: ${({ theme }) => theme.colors.white[1000]};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 16px 32px;
`;

const Subheader = styled(IonText)`
    ${({ theme }) => theme.text.h3}
    margin-top: 25px;
`;
const Information = styled(IonText)`
    ${({ theme }) => theme.text.paragraph.small}
    color: ${({ theme }) => theme.colors.white["640"]};
`;

const ContentWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("assets/logoBackground.svg");
`;

const ErrorPage = () => {
    const router = useIonRouter();

    const handleClick = () => {
        router.push("/dashboard");
    };

    return (
        <IonModal isOpen={true}>
            <Wrapper>
                <ContentWrapper>
                    <Icon404 />
                    <Subheader>Uuuups</Subheader>
                    <Information>Podana strona nie istnieje</Information>
                </ContentWrapper>
                <ModalButton onClick={handleClick} fullWidth>
                    Powrót do początku
                </ModalButton>
            </Wrapper>
        </IonModal>
    );
};

export default ErrorPage;
