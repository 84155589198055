import styled from "@emotion/styled";
import placeholder from "./../../../assets/img/placeholder.svg";
import { Training } from "../../../types/Training";
import SimplyButton from "../../Controls/Buttons/SimplyButton";
import { IconPdf } from "../../Icons/IconPdf";
import { useIonRouter } from "@ionic/react";
import { IconBlueQuiz } from "../../Icons/IconBlueQuiz";

const BoxWrapper = styled("div")`
    position: relative;
    text-decoration: none;
`;
const ImageWrapper = styled("div")<{
    photo: string;
}>`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(100% * (126 / 164));
    background-color: ${({ theme }) => theme.colors.gray["300"]};
    background-image: url(${({ photo }) => photo});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;
const TextWrapper = styled("div")`
    position: relative;
    display: grid;
    padding-top: 8px;
    padding-bottom: 24px;
    gap: 8px;
`;

const PostTitle = styled("h6")`
    ${({ theme }) => theme.text.h6}
    position: relative;
    color: ${({ theme }) => theme.colors.primary.gunmetal["1000"]};
    margin: 0;
    margin-bottom: 8px;
    &:first-of-type {
        margin-top: 4px;
    }
`;

interface ArticleListItemProps {
    item: Training;
}

export const TrainingListItem = (props: ArticleListItemProps) => {
    const openFileInNewTab = (fileURL: string) => {
        window.open(`${fileURL}`, "_blank");
    };
    const { item } = props;
    const router = useIonRouter();
    return (
        <BoxWrapper>
            <ImageWrapper photo={item.photos?.[0] ?? placeholder} />
            <TextWrapper>
                <PostTitle>{item.name}</PostTitle>
                <SimplyButton
                    text={"Pobierz"}
                    icon={<IconPdf />}
                    onClick={() => openFileInNewTab(item.file)}
                />
                <SimplyButton
                    text={"Wykonaj quiz"}
                    icon={<IconBlueQuiz />}
                    onClick={() => router.push(`/quiz/${item.quiz.id}`)}
                />
            </TextWrapper>
        </BoxWrapper>
    );
};
