import React from "react";
import styled from "@emotion/styled";

const Item = styled("div")<{ light?: boolean }>`
    ${({ theme }) => theme.text.paragraph.tiny}
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: ${({ light, theme }) =>
        light
            ? theme.colors.white["640"]
            : theme.colors.primary.gunmetal["640"]};
`;

const IconWrapper = styled("div")<{ light?: boolean }>`
    font-size: 16px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    color: ${({ light, theme }) =>
        light
            ? theme.colors.white["240"]
            : theme.colors.primary.gunmetal["400"]};
`;

export interface IconTextItemProps {
    text: string | null;
    icon?: JSX.Element;
    light?: boolean;
}

export const IconTextItem = (props: IconTextItemProps) => {
    const { text, icon, light } = props;
    return (
        <Item light={light}>
            <IconWrapper light={light}>{icon}</IconWrapper>
            {text}
        </Item>
    );
};
