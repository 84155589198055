export const IconChevronLeft = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="15"
            viewBox="0 0 9 15"
            fill="none"
        >
            <path
                d="M3.13139 7.5L8.56571 2.06568L7.43434 0.934311L0.868652 7.5L7.43434 14.0657L8.56571 12.9343L3.13139 7.5Z"
                fill="white"
            />
        </svg>
    );
};
